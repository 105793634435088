import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../../assets/css/style.css';
import { Link } from 'react-router-dom';

function ErrorPage() {
  return (
    <Container fluid className="container-404">
      <Row>
        <Col xs={12} className="lottie-div-404"></Col>
        <Col
          xs={10}
          xl={8}
          className="m-auto d-flex flex-column align-items-center justify-content-center">
          <h1 className="heading404">
            The page you are looking for might be removed or is temporarily unavailable
          </h1>
          <Link to="/">
            <Button className="btn404 p-3 my-4">Back to Homepage</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
}

export default ErrorPage;
