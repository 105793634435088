// import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import OurVisionImage from '../../assets/images/our_vision.jpeg';
import { getCurrentLanguage } from 'helpers';

const OurVision = ({ our_vision_data, t }) => {
  return (
    <>
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center mt-5 text-center "
        style={{ marginBottom: '80px' }}>
        <div className="our-vision">
          <Row>
            {/* Content */}
            <Col
              lg={6}
              xl={7}
              className="position-relative px- d-flex flex-column justify-content-center data-div ps-lg-4">
              <h1 className="our-vision-name">
                {our_vision_data?.about_setting_about_our_vision_title[`${getCurrentLanguage()}`] ||
                  our_vision_data?.about_setting_about_our_vision_title}
              </h1>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    our_vision_data?.about_setting_about_our_vision_description[
                      `${getCurrentLanguage()}`
                    ] || our_vision_data?.about_setting_about_our_vision_description,
                }}
                className="our-vision-description mt-3"
              />
            </Col>
            {/* Content */}
            <Col lg={6} xl={5} className="position-relative pe-lg-0">
              {/* Main Image */}
              <img
                src={our_vision_data?.about_setting_about_our_vision_image || OurVisionImage}
                alt="Main Display"
                className="img-fluid our-vision-image"
              />
              {/* Main Image */}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
OurVision.propTypes = {
  our_vision_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default OurVision;
