import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import contactBanner from '../../assets/images/contact-banner.png';
import { getCurrentLanguage } from '../../helpers';

const Banner = ({ banner_data, t }) => {
  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-center">
      <div className="contact-banner">
        <Row>
          <Col lg={12} className="position-relative p-0">
            <div className="contact-banner-image-div">
              {/* Banner Image */}
              <img
                src={banner_data?.contact_setting_contact_banner_image || contactBanner}
                alt="Main Display"
                className="img-fluid contact-banner-image"
              />
              <div className="shadowOverlay"></div>
              {/* Banner Image */}
            </div>
            <div className="contact-banner-container">
              <h1 className="contact-banner-title">
                {banner_data?.contact_setting_contact_banner_title[`${getCurrentLanguage()}`] ||
                  banner_data?.contact_setting_contact_banner_title}
              </h1>
              <br />
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
Banner.propTypes = {
  banner_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Banner;
