import React from 'react';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { Col, Row } from 'react-bootstrap';
import Vehicle1 from '../../assets/images/vehicle-1.png';
import Vehicle2 from '../../assets/images/vehicle-2.png';
import Vehicle3 from '../../assets/images/vehicle-3.png';

const BikeSlider = () => {
  const bikes = [
    {
      name: 'Triumph 1200 Tiger',
      brand: 'Bike',
      color: 'Yellow',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      price: '€27',
      price_unit: 'hour',
      rating: 2,
      image: Vehicle1,
    },
    {
      name: 'Triumph 1200 Tiger',
      brand: 'Bike',
      color: 'Green',
      description: 'Ut enim ad minim veniam, quis nostrud exercitation.',
      price: '€27',
      price_unit: 'hour',
      rating: 4,
      image: Vehicle2,
    },
    {
      name: 'Triumph 1200 Tiger',
      brand: 'Bike',
      color: 'Gray',
      description: 'Duis aute irure dolor in reprehenderit in voluptate.',
      price: '€27',
      price_unit: 'hour',
      rating: 3,
      image: Vehicle3,
    },
  ];

  // Owl Carousel options
  const options = {
    items: 2,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    dots: false,
    // nav: true,
    responsive: {
      0: {
        // For screens 0px and up (phones)
        items: 1, // Show 1 item
      },
      600: {
        // For screens 600px and up (small tablets)
        items: 1, // Still show 1 item
      },
      1000: {
        // For screens 1000px and up (tablets and larger devices)
        items: 2, // Show 1 item
      },
    },
  };

  return (
    <div className="carousel-container mt-5">
      <OwlCarousel options={options}>
        {bikes.length > 0 ? (
          bikes.map((bike, index) => (
            <div key={index} className="carousel-slide d-flex justify-content-center">
              {/* <Row className="m-3"> */}
              {/* <Col xs={12} sm={6} lg={4}> */}
              <div className="bike-col">
                <Row>
                  {/* Image side */}
                  <Col xs={12} lg={6} className="bike-image">
                    <div className="bike-image-container">
                      <img src={bike.image} alt={bike.name} className="img-fluid bike-image-img" />
                    </div>
                  </Col>

                  {/* Text side */}
                  <Col xs={12} lg={6} className="bike-info">
                    <p className="normal">{bike.brand}</p>
                    <h3>{bike.name}</h3>
                    <p className="color">{bike.color}</p>
                    <p className="description">{bike.description}</p>
                    <div className="rating">
                      {[...Array(5)].map((_, i) => (
                        <span
                          key={i}
                          className={i < Math.floor(bike.rating) ? 'filled-star' : 'empty-star'}>
                          ★
                        </span>
                      ))}
                    </div>
                    <p>
                      <span className="price">{bike.price}</span>/{' '}
                      <span className="price_unit">{bike.price_unit}</span>
                    </p>
                    <button className="rent-button w-100">Rent Now</button>
                  </Col>
                </Row>
              </div>
              {/* </Col>
              </Row> */}
            </div>
          ))
        ) : (
          <p>No motorcycles available at the moment.</p>
        )}
      </OwlCarousel>
    </div>
  );
};

export default BikeSlider;
