import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/scss/page/_contact.scss';
import Banner from './Banner';
import Information from './Information';
import { LoadingShimmer } from '../../common/shimmer';
const Main = ({ getSettingData, t }) => {
  return (
    <>
      {getSettingData ? (
        <div className="contact-container-main">
          {/** Banner Section **/}

          <Banner banner_data={getSettingData?.data} t={t} />
          {/** Banner Section **/}

          {/** Information Section **/}
          <Information contact_info={getSettingData?.data} t={t} />
          {/** Information Section **/}
        </div>
      ) : (
        <>
          <div className="text-center mt-3 mb-3">
            <LoadingShimmer />
          </div>
        </>
      )}
    </>
  );
};
Main.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Main;
