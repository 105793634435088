import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useFormik } from 'formik';
import Flag from 'react-world-flags';

import { FaPhone, FaEnvelope, FaLocationDot } from 'react-icons/fa6';
// import GoogleMapImage from '../../assets/images/contact-google_map.png';
// import Pin from '../../assets/images/google-pin.png';
import { getCurrentLanguage } from '../../helpers';
import { useGetServiceData, useContactInformationSent, useGetCountryData } from 'hooks';
import Pin from '../../assets/images/red-dot.png';
import validationSchema from './InformationValidation';
import Select from 'react-select';
import { toast } from 'react-toastify';

const Information = ({ contact_info, t }) => {
  const [privacyChecked, setPrivacyChecked] = React.useState(false);
  const [agreeChecked, setAgreeChecked] = React.useState(false);

  const countryStyle = {
    // placeholder: (defaultStyles) => ({
    //   ...defaultStyles,
    //   color: '#888', // Placeholder text color
    //   fontStyle: 'italic', // Italic text
    //   fontSize: '14px', // Adjust font size
    //   paddingLeft: '10px', // Add padding for alignment
    //   fontFamily: 'Montserrat, sans-serif', // Custom font
    // }),
    control: (base, state) => ({
      ...base,
    }),
    menu: (provided) => ({
      ...provided,
      overflowY: 'auto', // Enable scroll
      padding: '0px important',
    }),
    option: (provided) => ({
      ...provided,
      height: '40px', // Adjust option height
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: '#333333', // Text color when selected or not
      backgroundColor: '#FFFFFF', // Background color
      ':hover': {
        backgroundColor: '#FFFFFF', // Background color on hover
        color: '#333333', // Text color on hover
      },
    }),
  };

  const { mutate: doSendContactInformation, isLoading } = useContactInformationSent(
    (response) => {
      toast.success(response.message);
      formik.resetForm();
      window.location.reload();
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      service_id: '',
      privacyCheck: false,
      country_code_id: '34',
      agreeCheck: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      doSendContactInformation(values);
    },
  });

  // From this api we are getting country code list.
  const { data: getCountryData, isLoading: countryIsLoad } = useGetCountryData();
  const countryOptions = [];
  if (getCountryData !== undefined) {
    getCountryData.data.map((val) => {
      return countryOptions.push({
        code: val.code,
        value: val.phone_code,
        label: val.full_phone_code,
        image: val.country_image_url,
      });
    });
  }

  // From this api we are getting country code list.
  const { data: getServiceData } = useGetServiceData();
  const options = [];
  if (getServiceData !== undefined) {
    getServiceData.data.map((val) => {
      return options.push({
        value: val.services_id,
        label: `EN ${val.name['en'] || ''} | SP ${val.name['sp'] || ''}`,
      });
    });
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const containerStyle = {
    width: '552px',
    height: '250px',
    objectFit: 'cover',
    textAlign: 'left',
    borderRadius: '10px',
  };

  const markerOne = {
    position: {
      lat: Number(
        contact_info?.contact_setting_contact_info_one_address_lat?.[`${getCurrentLanguage()}`] ||
          36.658842
      ),
      lng:
        Number(
          contact_info?.contact_setting_contact_info_one_address_long?.[`${getCurrentLanguage()}`]
        ) || -4.578647,
    },
    icon: Pin,
  };

  const markerTwo = {
    position: {
      lat:
        Number(
          contact_info?.contact_setting_contact_info_two_address_lat?.[`${getCurrentLanguage()}`]
        ) || 41.337063,
      lng:
        Number(
          contact_info?.contact_setting_contact_info_two_address_long?.[`${getCurrentLanguage()}`]
        ) || 1.999817,
    },
    icon: Pin,
  };

  const handleCheckbox = () => {
    if (privacyChecked) {
      setPrivacyChecked(false);
      formik.setFieldValue('privacyCheck', false);
    } else {
      setPrivacyChecked(true);
      formik.setFieldValue('privacyCheck', true);
    }
  };

  const handleAgreeCheckbox = () => {
    if (agreeChecked) {
      setAgreeChecked(false);
      formik.setFieldValue('agreeCheck', false);
    } else {
      setAgreeChecked(true);
      formik.setFieldValue('agreeCheck', true);
    }
  };

  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-center p-0 container-fluid">
      <div className="information">
        <Row>
          <Col lg={6} className="p-0">
            <div className="form-container">
              <h2>Contact Us</h2>
              <form onSubmit={formik.handleSubmit}>
                {/* Name Field */}
                <div className="form-group">
                  <label htmlFor="name">{t('front_page.contact_form_name_title')}</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder={t('front_page.contact_form_name_placeholder')}
                    className={
                      'form-control ' +
                      (formik.touched.name && formik.errors.name
                        ? 'form-field-error'
                        : formik.touched.name && !formik.errors.name
                          ? 'form-field-success'
                          : '')
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(`name`, value.replace(/^\s+/g, ''));
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger mt-2">{t(formik.errors.name)}</div>
                    ) : null}
                  </div>
                </div>

                {/* Email Field */}
                <div className="form-group">
                  <label htmlFor="email">{t('front_page.contact_form_email_title')}</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder={t('front_page.contact_form_email_placeholder')}
                    className={
                      'form-control ' +
                      (formik.touched.email && formik.errors.email
                        ? 'form-field-error'
                        : formik.touched.email && !formik.errors.email
                          ? 'form-field-success'
                          : '')
                    }
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={(e) => {
                      const value = e.target.value;
                      formik.setFieldValue(`email`, value.replace(/^\s+/g, ''));
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger mt-2">{t(formik.errors.email)}</div>
                    ) : null}
                  </div>
                </div>

                {/* Phone Number Field */}
                <div className="form-group ">
                  <label htmlFor="phone">{t('front_page.contact_form_phone_title')}</label>
                  <div className="phone-field">
                    <div className="phone-country-code">
                      <Select
                        styles={countryStyle}
                        name="service_id"
                        placeholder={t('front_page.contact_form_country_placeholder')}
                        options={countryIsLoad ? countryOptions : countryOptions}
                        getOptionLabel={(e) => (
                          <div>
                            <Flag code={e.code} style={{ width: '20px', marginRight: '10px' }} />
                            {e.label}
                          </div>
                        )}
                        className={
                          'select-form-control' + formik.touched.country_code_id &&
                          formik.errors.country_code_id
                            ? 'form-select-error'
                            : formik.touched.country_code_id && !formik.errors.country_code_id
                              ? 'form-select-success'
                              : ''
                        }
                        onChange={(selectedOption1) => {
                          console.log('selectedOption1', selectedOption1);
                          if (selectedOption1 != null) {
                            formik.setFieldValue('country_code_id', selectedOption1.value);
                          } else {
                            formik.setFieldValue('country_code_id', '');
                          }
                        }}
                        value={countryOptions.find(
                          (option) => option.value === formik.values.country_code_id
                        )}
                      />
                    </div>
                    <input
                      type="text"
                      id="pone"
                      name="pone"
                      placeholder={t('front_page.contact_form_phone_placeholder')}
                      className={
                        'form-control ' +
                        (formik.touched.phone && formik.errors.phone
                          ? 'form-field-error'
                          : formik.touched.phone && !formik.errors.phone
                            ? 'form-field-success'
                            : '')
                      }
                      onInput={(e) => {
                        // var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                        // e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
                        var value = e.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
                        var formattedValue = '';

                        // Check for the different possible formats and apply the mask
                        if (value.length > 0) {
                          formattedValue = '(' + value.substring(0, 3);
                          if (value.length > 3) {
                            formattedValue += ') ' + value.substring(3, 6);
                            if (value.length > 6) {
                              formattedValue += '-' + value.substring(6, 10);
                            }
                          }
                        }
                        e.target.value = formattedValue;
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      onChange={(e) => {
                        const value = e.target.value;
                        formik.setFieldValue(`phone`, value.replace(/^\s+/g, ''));
                      }}
                    />
                  </div>
                  <div className="form-field-error-text">
                    {formik.touched.country_code_id && formik.errors.country_code_id ? (
                      <div className="text-danger mt-2">{t(formik.errors.country_code_id)}</div>
                    ) : null}
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="text-danger mt-2">{t(formik.errors.phone)}</div>
                    ) : null}
                  </div>
                </div>

                {/* Service Dropdown */}
                <div className="form-group">
                  <label htmlFor="service">{t('front_page.contact_form_service_title')}</label>
                  <Select
                    name="service_id"
                    placeholder={t('front_page.contact_form_service_Placeholder')}
                    options={options}
                    styles={customStyles}
                    className={
                      'select-form-control' + formik.touched.service_id && formik.errors.service_id
                        ? 'form-select-error'
                        : formik.touched.service_id && !formik.errors.service_id
                          ? 'form-select-success'
                          : ''
                    }
                    onChange={(selectedOption1) => {
                      if (selectedOption1 != null) {
                        formik.setFieldValue('service_id', selectedOption1.value);
                      } else {
                        formik.setFieldValue('service_id', '');
                      }
                    }}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.service_id && formik.errors.service_id ? (
                      <div className="text-danger mt-2">{t(formik.errors.service_id)}</div>
                    ) : null}
                  </div>
                  {/* <select id="service" name="service" className="select-form-control">
                    <option value="Motorcycle Rentals">Motorcycle Rentals</option>
                    <option value="Adventure Tours">Adventure Tours</option>
                    <option value="Guided Tours">Guided Tours</option>
                  </select> */}
                </div>

                {/* Privacy Policy Checkbox */}
                <div className="privacy-policy-checkbox">
                  <input
                    type="checkbox"
                    id="privacyPolicy"
                    name="privacyPolicy"
                    checked={privacyChecked ? true : false}
                    onChange={() => handleCheckbox()}
                  />

                  <label htmlFor="privacyPolicy">
                    {t('front_page.contact_form_privacy_title')}
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      {t('front_page.contact_form_privacy')}
                    </a>
                    *
                  </label>
                </div>
                <div className="form-field-error-text">
                  {formik.touched.privacyCheck && formik.errors.privacyCheck ? (
                    <div className="text-danger mt-2">{t(formik.errors.privacyCheck)}</div>
                  ) : null}
                </div>

                {/* Communications Checkbox */}
                <div className="privacy-policy-checkbox mb-3">
                  <input
                    type="checkbox"
                    id="communications"
                    name="communications"
                    checked={agreeChecked ? true : false}
                    onChange={() => handleAgreeCheckbox()}
                  />
                  <label htmlFor="communications">{t('front_page.contact_form_agree_title')}</label>
                </div>
                <div className="form-field-error-text">
                  {formik.touched.agreeCheck && formik.errors.agreeCheck ? (
                    <div className="text-danger mt-2 mb-3">{t(formik.errors.agreeCheck)}</div>
                  ) : null}
                </div>

                {/* Submit Button */}
                <button type="submit" className="submit-button" disabled={isLoading}>
                  {isLoading ? 'Loading...' : 'Submit'}
                </button>
              </form>
            </div>
          </Col>
          <Col lg={6} className="p-0">
            <div className="address-info">
              <h5 className="title">
                {contact_info?.contact_setting_contact_info_request_title[
                  `${getCurrentLanguage()}`
                ] || contact_info?.contact_setting_contact_info_request_title}
              </h5>
              <p className="desc">
                {contact_info?.contact_setting_contact_info_description[
                  `${getCurrentLanguage()}`
                ] || contact_info?.contact_setting_contact_info_description}
              </p>
              <p className="address">
                <FaLocationDot className="address-icon" />
                {
                  contact_info?.contact_setting_contact_info_one_address?.[
                    `${getCurrentLanguage()}`
                  ]
                }
              </p>
              <p className="address">
                <FaLocationDot className="address-icon" />
                {
                  contact_info?.contact_setting_contact_info_two_address?.[
                    `${getCurrentLanguage()}`
                  ]
                }
              </p>
              <p className="phone">
                <a href={`tel:${contact_info?.contact_setting_contact_info_phone}`}>
                  <FaPhone className="phone-email-icon" />
                  {contact_info?.contact_setting_contact_info_phone}
                </a>
              </p>
              <p className="email">
                <a
                  href={`mailto:${contact_info?.contact_setting_contact_info_email}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <FaEnvelope className="phone-email-icon" />
                  {contact_info?.contact_setting_contact_info_email}
                </a>
              </p>
              {/* <p className="email">{contact_info?.contact_setting_contact_info_email}</p> */}
            </div>
            {/* <Row className="container">
              <Col lg={10}>
                <div className="contact-information-image-div">
                  <img
                    src={GoogleMapImage}
                    alt="Main Display"
                    className="img-fluid contact-google-map-img "
                  />
                </div>
              </Col>
              <Col lg={10} className="mt-5 mb-3">
                <div className="contact-information-image-div">
                  <img
                    src={GoogleMapImage}
                    alt="Main Display"
                    className="img-fluid contact-google-map-img"
                  />
                </div>
              </Col>
            </Row> */}
            <Row className="container">
              {isLoaded && (
                <>
                  <Col lg={10}>
                    <div className="contact-information-image-div">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={markerOne.position}
                        zoom={10}>
                        {/* <Marker
                          key={0}
                          position={markerOne.position}
                          icon={{
                            url: markerOne.icon,
                            scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                          }}>
                          <div className="map-address-div">
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_one_address}
                            </p>
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_phone}
                            </p>
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_email}
                            </p>
                          </div>
                        </Marker> */}
                        <Marker
                          key={0}
                          position={markerOne.position}
                          icon={{
                            url: markerOne.icon,
                            scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                          }}>
                          <div className="map-address-div">
                            <h4 className="title d-flex mt-1">
                              {
                                contact_info?.contact_setting_contact_one_address_title?.[
                                  `${getCurrentLanguage()}`
                                ]
                              }
                            </h4>
                            <p className="address d-flex mt-1">
                              {
                                contact_info?.contact_setting_contact_info_one_address?.[
                                  `${getCurrentLanguage()}`
                                ]
                              }
                            </p>
                            <a
                              href={`tel:${contact_info?.contact_setting_contact_info_phone}`}
                              className="phone d-flex mb-1">
                              <FaPhone className="address-icon mt-1" />
                              {contact_info?.contact_setting_contact_info_phone}
                            </a>
                            <a
                              href={`mailto:${contact_info?.contact_setting_contact_info_email}`}
                              className="email d-flex mt-2"
                              target="_blank"
                              rel="noopener noreferrer">
                              <FaEnvelope className="address-icon mt-1" />
                              {contact_info?.contact_setting_contact_info_email}
                            </a>
                          </div>
                        </Marker>
                      </GoogleMap>
                    </div>
                  </Col>

                  <Col lg={10} className="mt-5 mb-3">
                    <div className="contact-information-image-div">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={markerTwo.position}
                        zoom={10}>
                        {/* <Marker
                          key={1}
                          position={markerTwo.position}
                          icon={{
                            url: markerTwo.icon,
                            scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                          }}>
                          <div className="map-address-div">
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_two_address}
                            </p>
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_phone}
                            </p>
                            <p className="address">
                              {contact_info?.contact_setting_contact_info_email}
                            </p>
                          </div>
                        </Marker> */}
                        <Marker
                          key={0}
                          position={markerTwo.position}
                          icon={{
                            url: markerTwo.icon,
                            scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                          }}>
                          <div className="map-address-div">
                            <h4 className="title ">
                              {
                                contact_info?.contact_setting_contact_two_address_title?.[
                                  `${getCurrentLanguage()}`
                                ]
                              }
                            </h4>
                            <p className="address d-flex mt-1">
                              {
                                contact_info?.contact_setting_contact_info_two_address?.[
                                  `${getCurrentLanguage()}`
                                ]
                              }
                            </p>
                            <a
                              href={`tel:${contact_info?.contact_setting_contact_info_phone}`}
                              className="phone d-flex mb-1">
                              <FaPhone className="address-icon mt-1" />
                              {contact_info?.contact_setting_contact_info_phone}
                            </a>
                            <a
                              href={`mailto:${contact_info?.contact_setting_contact_info_email}`}
                              className="email d-flex mt-2"
                              target="_blank"
                              rel="noopener noreferrer">
                              <FaEnvelope className="address-icon mt-1" />
                              {contact_info?.contact_setting_contact_info_email}
                            </a>
                          </div>
                        </Marker>
                      </GoogleMap>
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

Information.propTypes = {
  contact_info: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Information;
