import React, { useState, useEffect } from 'react';
import { Button, Container, Dropdown, Nav, Navbar } from 'react-bootstrap';
import '../../../assets/css/style.css';
import logo from '../../../assets/images/triumph-logo.png';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types'; // Import PropTypes
import usa from '../../../assets/images/usa.png';
import spain from '../../../assets/images/spain.png';
import { useTranslation } from 'react-i18next';

function Header({ t }) {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language is English
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    setActiveLink(location.pathname); // Update activeLink when location changes
  }, [location.pathname]);
  const handleSelect = (path) => {
    setActiveLink(path);
    setMenuOpen(false); // Close the menu after selecting a link
    document.body.classList.remove('no-scroll');
  };

  const handleToggle = () => {
    setMenuOpen(!menuOpen);
    document.body.classList.toggle('no-scroll');
  };
  const headerLogoUrl = logo;
  const { i18n } = useTranslation();

  // Function to handle language change
  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang); // Change language using i18n
  };
  const getFlagIcon = (lang) => {
    switch (lang) {
      case 'sp':
        return spain; // Set Spain flag for Spanish
      case 'en':
      default:
        return usa; // Set USA flag for English
    }
  };

  const getLanguageText = (lang) => {
    switch (lang) {
      case 'sp':
        return t('front_page.header_language_sp');
      case 'en':
      default:
        return t('front_page.header_language_en');
    }
  };

  return (
    <Container fluid className="px-lg-5 main-page-header">
      <Navbar className="" expand="lg" expanded={menuOpen} onToggle={handleToggle}>
        <Container fluid className="p-lg-0">
          <Navbar.Brand href="/">
            <img
              src={headerLogoUrl}
              alt={t('front_page.header_logo_alt')}
              className="d-d-inline-block logo-image"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" className="p-0" />
          <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
            <Nav className="nav">
              <Link
                to="/"
                className={`navlink ${activeLink === '/' ? 'header-active-class' : ''}`}
                onClick={() => handleSelect('/')}>
                {t('front_page.header_nav_home')}
              </Link>
              <Link
                to="/rental"
                className={`navlink ${activeLink === '/rental' ? 'header-active-class' : ''}`}
                onClick={() => handleSelect('/rental')}>
                {t('front_page.header_nav_rentals')}
              </Link>
              <Link
                to="/about"
                className={`navlink ${activeLink === '/about' ? 'header-active-class' : ''}`}
                onClick={() => handleSelect('/about')}>
                {t('front_page.header_nav_about')}
              </Link>
              <Link
                to="/contact"
                className={`navlink ${activeLink === '/contact' ? 'header-active-class' : ''}`}
                onClick={() => handleSelect('/contact')}>
                {t('front_page.header_nav_contact')}
              </Link>
              <Dropdown className="language-switch me-4">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <span>
                    <img
                      src={getFlagIcon(selectedLanguage)}
                      alt={t('front_page.header_language_icon_alt')}
                    />
                  </span>
                  {getLanguageText(selectedLanguage)}
                </Dropdown.Toggle>

                <Dropdown.Menu className="p-0 mt-1">
                  {selectedLanguage !== 'en' && (
                    <Dropdown.Item onClick={() => handleLanguageChange('en')}>
                      <span>
                        <img src={usa} alt={t('front_page.header_language_icon_alt')} />
                      </span>
                      {t('front_page.header_language_en')}
                    </Dropdown.Item>
                  )}
                  {selectedLanguage !== 'sp' && (
                    <Dropdown.Item onClick={() => handleLanguageChange('sp')}>
                      <span>
                        <img src={spain} alt={t('front_page.header_language_icon_alt')} />
                      </span>
                      {t('front_page.header_language_sp')}
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              <Link to={'/register'} className="navlink">
                <Button className="login-btn bg-transparent">
                  <span className="btn-login-text">{t('front_page.header_login_signup')}</span>
                </Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
  );
}

// PropTypes validation
Header.propTypes = {
  t: PropTypes.func,
};

export default Header;
