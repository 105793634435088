import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import '../../assets/scss/page/_faq.scss';
import { Container, Row, Col, Nav, Accordion, Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import ContactUsIcon from '../../assets/images/contact-us.svg';
import { useGetFaqData, useGetSearchFaqData } from '../../hooks';
import { LoadingShimmer } from '../../common/shimmer';
import { NavLink } from 'react-router-dom';
import { getCurrentLanguage } from 'helpers';

const Main = ({ t }) => {
  const [searchFaq, setFaqSearch] = useState('');

  const {
    refetch: FaqRetch,
    data: getSearchFaqData,
    isLoading: searchFaqLoading,
  } = useGetSearchFaqData(
    {
      search_text: searchFaq,
      is_enabled: searchFaq ? true : false,
    },
    () => {},
    (error) => {
      if (error.data.redirect === true) {
        console.log('Error occurred: ', error.message);
      }
    }
  );
  const searchFaqData = getSearchFaqData?.data || [];

  const { data: getFaqData, isLoading } = useGetFaqData(
    () => {},
    (error) => {
      if (error.data.redirect === true) {
        console.log('Error occurred: ', error.message);
      }
    }
  );

  const faqData = getFaqData?.data || [];

  const initialActiveKey =
    faqData?.category_type?.length > 0 ? faqData?.category_type[0].faq_category_id : '0';

  const [activeKey, setActiveKey] = useState(initialActiveKey);

  const handleSelect = (eventKey) => {
    setActiveKey(eventKey);
  };
  useEffect(() => {
    if (faqData?.category_type?.length > 0) {
      setActiveKey(faqData?.category_type[0].faq_category_id.toString());
    }
  }, [faqData]);

  const handleSearch = (search_text) => {
    setFaqSearch(search_text.target.value);
    FaqRetch();
  };

  return (
    <>
      <div className="faq-container-main">
        <Container fluid className=" faq-container">
          <Row className="mt-4 px-4 mt-sm-5 container-fluid">
            <Col sm={6} md={6} xl={6} className="title text-left">
              <p className="text-left">{t('front_page.faq_title')}</p>
            </Col>
            <Col md={6} className="pe-0 search-box">
              <div className="search-container d-flex justify-content-end">
                <div className="search-bar">
                  <input
                    type="text"
                    placeholder={t('Search')}
                    className="search-input"
                    value={searchFaq}
                    onKeyUp={handleSearch}
                    onChange={handleSearch}
                  />
                  <div className="search-icon">
                    <FaSearch />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {!isLoading && !searchFaqLoading ? (
            <>
              {faqData && Object.values(faqData.category_type).length !== 0 ? (
                <Tab.Container
                  id="left-tabs-example"
                  defaultActiveKey="1"
                  activeKey={activeKey}
                  onSelect={handleSelect}>
                  <Row className="mt-4 px-md-4">
                    <Col sm={12} md={12} xl={2}>
                      <Nav className="flex-xl-column flex-lg-row  align-items-start faq-tab justify-content-md-start justify-content-xl-start faq-nav">
                        {faqData?.category_type.map((category) => (
                          <Nav.Item key={category.faq_category_id}>
                            <Nav.Link
                              eventKey={category.faq_category_id}
                              className={`faq-link ${
                                activeKey === category.faq_category_id ? 'tab-active-class' : ''
                              }`}>
                              {category.category_name?.[`${getCurrentLanguage()}`] || ''}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </Col>
                    <Col sm={12} md={12} xl={10} className="px-4 mt-5 mt-xl-0 faq-content">
                      <Tab.Content>
                        {!searchFaq ? (
                          <>
                            {faqData?.category_type.map((category) => (
                              <Tab.Pane
                                key={category.faq_category_id}
                                eventKey={category.faq_category_id}>
                                <Accordion defaultActiveKey="0">
                                  {category.faqs && Object.values(category.faqs).length !== 0 ? (
                                    <>
                                      {category.faqs.map((faqItem) => (
                                        <Accordion.Item
                                          key={faqItem._id}
                                          eventKey={faqItem._id}
                                          className="mb-3">
                                          <Accordion.Header className="acc-header">
                                            {faqItem.question?.[`${getCurrentLanguage()}`] || ''}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  faqItem.answer?.[`${getCurrentLanguage()}`] || '',
                                              }}
                                            />
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <Row>
                                        <Col sm={12} md={12} xl={12}>
                                          <h3 className="text-center mt-5">
                                            {t('front_page.faq_no_data_found')}
                                          </h3>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                </Accordion>
                              </Tab.Pane>
                            ))}
                          </>
                        ) : (
                          <>
                            <Accordion defaultActiveKey="0">
                              {searchFaqData.category_type &&
                              Object.values(searchFaqData.category_type).length !== 0 ? (
                                <>
                                  {searchFaqData.category_type.map((faqItem) => (
                                    <Accordion.Item
                                      key={faqItem._id}
                                      eventKey={faqItem._id}
                                      className="mt-3">
                                      <Accordion.Header className="acc-header">
                                        {faqItem.question?.[`${getCurrentLanguage()}`] || ''}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              faqItem.answer?.[`${getCurrentLanguage()}`] || '',
                                          }}
                                        />
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <Row>
                                    <Col sm={12} md={12} xl={12}>
                                      <h3 className="text-center mt-5">
                                        {t('front_page.faq_no_data_found')}
                                      </h3>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Accordion>
                          </>
                        )}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              ) : (
                <>
                  <Row>
                    <Col sm={12} md={12} xl={12}>
                      <h3 className="text-center mt-5">{t('front_page.faq_no_data_found')}</h3>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <div className="text-center mt-3 mb-3">
                <LoadingShimmer />
              </div>
            </>
          )}
          <Row className="mt-5 faq-banner">
            <Container fluid className="footer-append-div row-gap-3">
              <Col xs={12} sm={2} className="text-center">
                <img src={ContactUsIcon} alt="contact-icon" />
              </Col>
              <Col xs={12} sm={7} className="text-sm-left">
                <p>{t('front_page.footer_add_on_contact_us_description')}</p>
              </Col>
              <Col xs={12} sm={3} className="text-center">
                <Button className="contact-us-btn">
                  <NavLink to={'/contact'} className="Link">
                    {t('front_page.footer_add_on_contact_us_btn_text')}
                  </NavLink>
                </Button>
              </Col>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};
Main.propTypes = {
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Main;
