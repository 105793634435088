import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { PublicLayout } from '../common/layouts';
import { useSelector } from 'react-redux';
import Loader from '../common/layouts/Loader';
import { loader } from '../store/features/loaderSlice';

const PublicRoute = ({ getSettingData, t }) => {
  const checkLoading = useSelector(loader);
  return (
    <PublicLayout getSettingData={getSettingData} t={t}>
      {checkLoading ? <Loader /> : ''}
      <Outlet />
    </PublicLayout>
  );
};

PublicRoute.propTypes = {
  getSettingData: PropTypes.object.isRequired, // Adjust the PropTypes validation based on the type of getSettingData
  t: PropTypes.func,
};

export default PublicRoute;
