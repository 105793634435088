import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import BannerImage from '../../assets/images/banner.png';
import { getCurrentLanguage } from 'helpers';
const Banner = ({ our_banner_data, t }) => {
  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-center">
      <div className="banner">
        <Row>
          <Col lg={12} className="position-relative p-0">
            <div className="banner-image-div">
              {/* Banner Image */}
              <img
                src={our_banner_data?.about_setting_about_banner_image || BannerImage}
                alt="Main Display"
                className="img-fluid banner-image"
              />
              {/* Banner Image */}
            </div>
            <div className="banner-container">
              <h1 className="banner-title">
                {our_banner_data?.about_setting_about_banner_title[`${getCurrentLanguage()}`] ||
                  our_banner_data?.about_setting_about_banner_title}
              </h1>
              <p className="banner-short-desc">
                {our_banner_data?.about_setting_about_banner_short_description[
                  `${getCurrentLanguage()}`
                ] || our_banner_data?.about_setting_about_banner_short_description}
              </p>
            </div>
          </Col>
          <Col lg={12} className="">
            <div className="banner-description">
              {/* Banner Description */}
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    our_banner_data?.about_setting_about_banner_description[
                      `${getCurrentLanguage()}`
                    ] || our_banner_data?.about_setting_about_banner_description,
                }}
                className="description"
              />
              {/* Banner Description */}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
Banner.propTypes = {
  our_banner_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Banner;
