import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { useGetCMSData } from 'hooks';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ContactUsIcon from '../../assets/images/contact-us.svg';
import PropTypes from 'prop-types'; // Import PropTypes
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { getCurrentLanguage } from '../../helpers/';

function CMS({ t }) {
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Show loader when component mounts
    dispatch(showLoader());
    setIsLoading(true);
  }, [dispatch]);
  const { data: getCMSData, isSuccess: isCMSSuccess } = useGetCMSData(
    slug,
    () => {},
    (error) => {
      console.log('In this api error occurs ' + error.message);
      setIsLoading(false);
      if (error) {
        navigate('/error');
      }
    }
  );
  useEffect(() => {
    // Close loader when all data is successfully fetched
    if (isCMSSuccess) {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  }, [isCMSSuccess, dispatch]);
  const pageTitle = getCMSData?.data?.seo_meta_title?.[`${getCurrentLanguage()}`] || 'Triumph';
  const seoTitle = getCMSData?.data?.seo_meta_title?.[`${getCurrentLanguage()}`] || 'Triumph';
  const seoDescription = getCMSData?.data?.seo_meta_desc?.[`${getCurrentLanguage()}`] || 'Triumph';
  return (
    <>
      {isLoading ? (
        <p>Loading...</p> // Placeholder for loader
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="title" content={seoTitle} />
            <meta name="description" content={seoDescription} />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDescription} />
          </Helmet>
          <Container fluid className="content-div">
            <Row>
              <Col className="m-2 m-md-5">
                <Row>
                  <h1 className="title text-left mt-3 mb-3 ml-3 mr-3 cms-title">
                    {getCMSData?.data?.title?.[`${getCurrentLanguage()}`] || ''}
                  </h1>
                </Row>
                <Row className="content-container p-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getCMSData?.data?.description?.[`${getCurrentLanguage()}`] || '',
                    }}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
          <Container fluid className="footer-append-div">
            <Col xs={12} sm={2} className="text-center">
              <img src={ContactUsIcon} alt="contact-icon" />
            </Col>
            <Col xs={12} sm={8} className="text-sm-left">
              <p>{t('front_page.footer_add_on_contact_us_description')}</p>
            </Col>
            <Col xs={12} sm={2} className="text-center">
              <Button className="contact-us-btn">
                <NavLink to={'/contact'} className="Link">
                  {t('front_page.footer_add_on_contact_us_btn_text')}
                </NavLink>
              </Button>
            </Col>
          </Container>
        </>
      )}
    </>
  );
}
CMS.propTypes = {
  t: PropTypes.func.isRequired, // Assuming t is a function
};
export default CMS;
