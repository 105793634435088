import React, { useState, useEffect } from 'react';
import { Container, Carousel, Tabs, Tab, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
// import '../../assets/css/style.css'; // Your custom CSS
import '../../assets/scss/page/_homepage.scss';
import ServiceImage1 from '../../assets/images/image-1.png';
import HelmetIcon from '../../assets/svg/HelmetIcon';
import { useGetServiceData } from 'hooks';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import BikeSlider from './BikeSlider';
import Review from './Review';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { getCurrentLanguage } from '../../helpers/';

function Main({ getSettingData, t }) {
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const [tabsData, setTabsData] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [selectedImages, setSelectedImages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Show loader when component mounts
    dispatch(showLoader());
    setIsLoading(true);
  }, [dispatch]);
  const { data: getServiceData, isSuccess: isServiceSuccess } = useGetServiceData(
    () => {},
    (error) => {
      console.log('In this api error occurs ' + error.message);
      setIsLoading(false);
    }
  );
  useEffect(() => {
    if (getServiceData?.data) {
      // Dynamically set tabsData
      setTabsData(getServiceData.data);

      // Set the first tab key as activeTab
      const firstTabKey = getServiceData.data[0]?.key || '';
      setActiveTab(firstTabKey);

      // Create a dynamic selectedImages object
      const images = getServiceData.data.reduce((acc, tab) => {
        acc[tab.key] = tab.mainImage;
        return acc;
      }, {});
      setSelectedImages(images);
    }
  }, [getServiceData]);
  useEffect(() => {
    // Close loader when all data is successfully fetched
    if (isServiceSuccess) {
      setIsLoading(false);
      dispatch(hideLoader());
    }
  }, [isServiceSuccess, dispatch]);

  useEffect(() => {
    // Show loader if data fetching fails
    if (!isServiceSuccess) {
      setIsLoading(false);
      dispatch(showLoader());
    }
  }, [isServiceSuccess, dispatch]);
  const handleImageClick = (image, tabKey) => {
    const updatedTabData = tabsData.find((tab) => tab.key === tabKey);
    const updatedSelectedImages = { ...selectedImages, [tabKey]: image };
    const updatedThumbnails = updatedTabData.thumbnailImages.filter((img) => img !== image);
    updatedThumbnails.push(selectedImages[tabKey]);
    const updatedTabsData = [...tabsData];
    updatedTabsData.find((tab) => tab.key === tabKey).thumbnailImages = updatedThumbnails;
    setSelectedImages(updatedSelectedImages);
    setTabsData(updatedTabsData);
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    if (!selectedImages[key]) {
      setSelectedImages((prev) => ({
        ...prev,
        [key]: tabsData.find((tab) => tab.key === key).mainImage,
      }));
    }
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const processBannerData = (data) => {
    // Ensure the data is not null or undefined
    if (!data) {
      console.error('No banner data available');
      return []; // Return an empty array if data is missing
    }

    const banners = [];

    // Ensure we have valid banner keys and find the max index
    const bannerKeys = Object.keys(data).filter((key) => key.includes('home_setting_home_banner_')); // Only consider banner-related keys

    if (bannerKeys.length === 0) {
      console.error('No valid banner data found');
      return []; // Return an empty array if no valid banner keys
    }

    const maxIndex = Math.max(
      ...bannerKeys.map((key) => {
        const match = key.match(/\d+/); // Extract the numeric index from the key
        return match ? parseInt(match[0]) : 0; // Return the parsed number or 0 if no match
      })
    );

    // Loop through each banner index from 1 to maxIndex and push the data into the banners array
    for (let i = 1; i <= maxIndex; i++) {
      banners.push({
        title: data[`home_setting_home_banner_${i}_title`][`${getCurrentLanguage()}`] || '',
        description:
          data[`home_setting_home_banner_${i}_description`][`${getCurrentLanguage()}`] || '',
        image: data[`home_setting_home_banner_${i}_image`] || '',
      });
    }

    return banners;
  };
  const options = {
    items: 1,
    loop: false,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 5000,
    dots: true,
    center: false,
    // nav: true,
    responsive: {
      0: {
        // For screens 0px and up (phones)
        items: 1, // Show 1 item
      },
      600: {
        // For screens 600px and up (small tablets)
        items: 2, // Still show 1 item
      },
      1000: {
        // For screens 1000px and up (tablets and larger devices)
        items: 2, // Show 1 item
        margin: 30,
      },
      1280: {
        items: 3,
        margin: 20,
      },
    },
  };

  // for thumbnail images
  const options2 = {
    items: 1,
    loop: false,
    margin: 10,
    autoplay: false,
    autoplayTimeout: 5000,
    dots: false,
    nav: true,
    navText: [
      // Left Arrow Custom SVG
      '<span class="owl-prev"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 18l-6-6 6-6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
      // Right Arrow Custom SVG
      '<span class="owl-next"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6l6 6-6 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></span>',
    ],
    responsive: {
      0: {
        // For screens 0px and up (phones)
        items: 4, // Show 1 item
      },
      600: {
        // For screens 600px and up (small tablets)
        items: 4, // Still show 1 item
      },
      1000: {
        // For screens 1000px and up (tablets and larger devices)
        items: 4, // Show 1 item
      },
    },
  };

  // Extract and format the data for the carousel
  const bannerSettings = getSettingData?.data ? processBannerData(getSettingData.data) : [];
  return (
    <>
      {isLoading ? (
        <p>Loading...</p> // Placeholder for loader
      ) : (
        <>
          <div className="home-contanier-main">
            {/** Banner Section **/}
            {bannerSettings.length !== 0 && (
              <Container fluid className="p-0 carousel-container position-relative">
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                  interval={3000}
                  indicators={false}
                  prevLabel=""
                  nextLabel="">
                  {bannerSettings.map((banner, idx) => (
                    <Carousel.Item key={idx}>
                      <div className="mask-image"></div>
                      <img className="d-block w-100" src={banner.image} alt={`Slide ${idx + 1}`} />
                      <Carousel.Caption className="carousel-caption-upper">
                        <span className="hero-main-title">{banner.title}</span>
                        <p className="hero-sub-title">{banner.description}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  ))}
                </Carousel>
                <a
                  className="btn btn-primary join-us-btn bg-transparent"
                  href={getSettingData?.data?.home_setting_home_banner_join_us_btn_link || ''}>
                  <span className="btn-join-text">
                    {getSettingData?.data?.home_setting_home_banner_join_us_btn_txt[
                      `${getCurrentLanguage()}`
                    ] || 'Join Us'}
                  </span>
                </a>
              </Container>
            )}

            {/** Service Section **/}
            {tabsData.length !== 0 && (
              <Container fluid className="p-0 service-section">
                <h1 className="service-title text-center">{t('front_page.home_service_label')}</h1>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                  className="custom-tabs mb-3 justify-content-center">
                  {tabsData.map((tab) => (
                    <Tab
                      eventKey={tab.key}
                      title={tab.name[`${getCurrentLanguage()}`]}
                      key={tab.key}>
                      <Container fluid className="mt-5 tab-details">
                        <Row>
                          <Col lg={6} className="position-relative ps-0 service-img-div">
                            {/* Main Image */}
                            <img
                              src={selectedImages[tab.key]}
                              alt="Main Display"
                              className="img-fluid main-image"
                            />
                            {/* Thumbnails Row */}
                            <div className="thumbnail-row justify-content-center gap-md-5">
                              <div className="carousel-container thumbnl">
                                <OwlCarousel options={options2}>
                                  {tab.thumbnailImages.map((image, index) => (
                                    <span
                                      key={index}
                                      className="carousel-slide"
                                      style={{ display: 'inline-block', height: 'fit-content' }}
                                      onClick={() => handleImageClick(image, tab.key)}>
                                      <img
                                        src={image}
                                        alt={`Thumbnail ${index + 1}`}
                                        className={`img-fluid small-image ${selectedImages[tab.key] === image ? 'selected' : ''}`}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    </span>
                                  ))}
                                </OwlCarousel>
                              </div>
                            </div>
                          </Col>
                          <Col
                            lg={6}
                            className="position-relative px- d-flex flex-column justify-content-center service-details-div">
                            <h1 className="service-name">
                              {tab.title[`${getCurrentLanguage()}`] || ''}
                            </h1>
                            <p className="service-para mt-3">
                              {tab.content[`${getCurrentLanguage()}`]}
                            </p>
                            <a className="btn btn-primary explore-btn mt-4" href={tab.buttonLink}>
                              <HelmetIcon />
                              <span className="mx-2">{t('front_page.home_explore_now_label')}</span>
                            </a>
                          </Col>
                        </Row>
                      </Container>
                      <Container fluid className="feature-div">
                        <Row className="m-3 justify-content-center gap-5">
                          <div className="carousel-container mt-5">
                            <OwlCarousel options={options}>
                              {tabsData
                                .find((tab) => tab.key === activeTab)
                                .features.map((feature, index) => (
                                  <div
                                    key={index}
                                    className="carousel-slide d-flex justify-content-center">
                                    <Col md={4} key={index} className="gear-rental-card">
                                      {/* <div className="gear-rental-card"> */}
                                      <div className="card-content">
                                        <div className="card-icon">
                                          <img
                                            className="d-block "
                                            src={feature.icon}
                                            alt={`Feature ${index + 1}`}
                                          />
                                        </div>
                                        <div className="card-text feature-head">
                                          <h4>{feature.title[`${getCurrentLanguage()}`]}</h4>
                                        </div>
                                      </div>
                                      <div className="card-content">
                                        <p className="card-content2 mt-3">
                                          {feature.description[`${getCurrentLanguage()}`]}
                                        </p>
                                      </div>
                                      {/* </div> */}
                                    </Col>
                                  </div>
                                ))}
                            </OwlCarousel>
                          </div>
                        </Row>
                      </Container>
                    </Tab>
                  ))}
                </Tabs>
              </Container>
            )}
            {/** Triumph Motor Cycle Lineup Section **/}
            <Container
              fluid
              className="d-flex flex-column align-items-center justify-content-center triumph-div text-center">
              <Row className="justify-content-center">
                <Col lg={12} className="motorcycle-lineup">
                  <h1 className="triumph-title">
                    {getSettingData?.data?.home_setting_home_motorcycle_lineup_title[
                      `${getCurrentLanguage()}`
                    ] || 'TRIUMPH MOTORCYCLE LINEUP'}
                  </h1>
                  <p className="triumph-desc limited-width">
                    {getSettingData?.data?.home_setting_home_motorcycle_lineup_description[
                      `${getCurrentLanguage()}`
                    ] ||
                      'Rent the latest generation Triumph motorcycles in Málaga and Barcelona.You can also hire gear and accessories to enjoy a complete two-wheel experience'}
                  </p>
                </Col>
              </Row>
            </Container>
            <BikeSlider />
            {/** Why Choose Us Section **/}
            <Container
              fluid
              className="d-flex flex-column align-items-center justify-content-center triumph-div text-center">
              <h3 className="why-choose-heading">{t('front_page.home_why_choose_us')}</h3>
              <Row className="why-choose-us mt-4">
                <Col className="wcu-main" lg>
                  <div className="wcu-img-div">
                    <img
                      src={
                        getSettingData?.data?.home_setting_why_choose_us_one_image || ServiceImage1
                      }></img>
                    <h1 className="wcu-count">1</h1>
                  </div>
                  <h4 className="wcu-title">
                    {getSettingData?.data?.home_setting_why_choose_us_one_title[
                      `${getCurrentLanguage()}`
                    ] || 'MOTORCYCLE CLOTHING & GEAR'}
                  </h4>
                  <p className="wcu-para">
                    {getSettingData?.data?.home_setting_why_choose_us_one_description[
                      `${getCurrentLanguage()}`
                    ] ||
                      'All necessary clothing and protections for the development of your adventure with the utmost safety'}
                  </p>
                </Col>
                <Col className="wcu-main" lg>
                  <div className="wcu-img-div">
                    <img
                      src={
                        getSettingData?.data?.home_setting_why_choose_us_two_image || ServiceImage1
                      }></img>
                    <h1 className="wcu-count">2</h1>
                  </div>
                  <h4 className="wcu-title">
                    {getSettingData?.data?.home_setting_why_choose_us_two_title[
                      `${getCurrentLanguage()}`
                    ] || 'FACILITY & ARENA'}
                  </h4>
                  <p className="wcu-para">
                    {getSettingData?.data?.home_setting_why_choose_us_two_description[
                      `${getCurrentLanguage()}`
                    ] ||
                      '400 m2 facility and stunning private Arena, dedicated to your passion and only 10 minutes from Malaga´s airport.'}
                  </p>
                </Col>
                <Col className="wcu-main" lg>
                  <div className="wcu-img-div">
                    <img
                      src={
                        getSettingData?.data?.home_setting_why_choose_us_three_image ||
                        ServiceImage1
                      }></img>
                    <h1 className="wcu-count">3</h1>
                  </div>
                  <h4 className="wcu-title">
                    {getSettingData?.data?.home_setting_why_choose_us_three_title[
                      `${getCurrentLanguage()}`
                    ] || ' INSTRUCTORS FOR OFF-ROAD TRAINING'}
                  </h4>
                  <p className="wcu-para">
                    {getSettingData?.data?.home_setting_why_choose_us_three_description[
                      `${getCurrentLanguage()}`
                    ] ||
                      'The best off-road training method by the hand of our team of Triumph accredited instructors.'}
                  </p>
                </Col>
                <Col className="wcu-main" lg>
                  <div className="wcu-img-div last">
                    <img
                      src={
                        getSettingData?.data?.home_setting_why_choose_us_four_image || ServiceImage1
                      }></img>
                    <h1 className="wcu-count">4</h1>
                  </div>
                  <h4 className="wcu-title">
                    {getSettingData?.data?.home_setting_why_choose_us_four_title[
                      `${getCurrentLanguage()}`
                    ] || 'TRIUMPH MOTORCYCLES'}
                  </h4>
                  <p className="wcu-para">
                    {getSettingData?.data?.home_setting_why_choose_us_four_description[
                      `${getCurrentLanguage()}`
                    ] || 'Off-road experiences, expert tuition, latest adventure bikes.'}
                  </p>
                </Col>
              </Row>
            </Container>
            {/** Riders Reviews Section **/}
            <Review t={t} />
          </div>
        </>
      )}
    </>
  );
}

Main.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Main;
