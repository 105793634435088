import * as Yup from 'yup';

let validationSchema = Yup.object({
  name: Yup.string('front_page.contact_form_name_required')
    .required('front_page.contact_form_name_required')
    .trim('front_page.contact_form_name_required')
    .max(255, 'front_page.contact_form_name_max'),
  country_code_id: Yup.string('front_page.contact_form_country_required').required(
    'front_page.contact_form_country_required'
  ),

  phone: Yup.string('front_page.contact_form_phone_required')
    .required('front_page.contact_form_phone_required')
    .trim('front_page.contact_form_phone_required')
    .max(25, 'front_page.contact_form_phone_max'),
  service_id: Yup.mixed('front_page.contact_form_service_required').required(
    'front_page.contact_form_service_required'
  ),
  email: Yup.string('front_page.contact_form_email_required')
    .trim('front_page.contact_form_email_required')
    .required('front_page.contact_form_email_required')
    .email('front_page.contact_form_email_Invalid')
    .test('has-domain', 'front_page.contact_form_email_Invalid', function (value) {
      // Regex to check if domain exists in the email
      const domainRegex = /@([a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)/;
      const isValidDomain = domainRegex.test(value);
      return (
        isValidDomain || this.createError({ message: 'front_page.contact_form_email_Invalid' })
      );
    })
    .max(100, 'front_page.contact_form_email_max'),

  privacyCheck: Yup.bool('front_page.contact_form_privacy_check_required')
    .required('front_page.contact_form_privacy_check_required')
    .oneOf([true], 'front_page.contact_form_privacy_check_required'),

  agreeCheck: Yup.bool('front_page.contact_form_agree_check_required')
    .required('front_page.contact_form_agree_check_required')
    .oneOf([true], 'front_page.contact_form_agree_check_required'),
});

export default validationSchema;
