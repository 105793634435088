import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import '../../../assets/css/style.css';
import logo from '../../../assets/images/triumph-logo-2.svg';
import { FaPhone, FaEnvelope, FaLocationDot } from 'react-icons/fa6';
import {
  FaWhatsapp,
  FaFacebook,
  FaInstagram,
  FaTelegram,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCurrentLanguage } from '../../../helpers/';

const iconMap = {
  whatsapp: FaWhatsapp,
  facebook: FaFacebook,
  instagram: FaInstagram,
  telegram: FaTelegram,
  linkedin: FaLinkedin,
  youtube: FaYoutube,
  phone: FaPhone,
  email: FaEnvelope,
  location: FaLocationDot,
};

const getLinkType = (url) => {
  const lowerCaseUrl = url.toLowerCase(); // Normalize URL to lowercase for consistent matching
  if (lowerCaseUrl.includes('whatsapp.com')) return 'whatsapp';
  if (lowerCaseUrl.includes('facebook.com')) return 'facebook';
  if (lowerCaseUrl.includes('instagram.com')) return 'instagram';
  if (lowerCaseUrl.includes('telegram.me') || lowerCaseUrl.includes('t.me')) return 'telegram'; // Handle Telegram short links
  if (lowerCaseUrl.includes('linkedin.com') || lowerCaseUrl.includes('lnkd.in')) return 'linkedin'; // Handle LinkedIn short links
  if (lowerCaseUrl.includes('youtube.com') || lowerCaseUrl.includes('youtu.be')) return 'youtube'; // Handle YouTube short links
  if (lowerCaseUrl.startsWith('mailto:')) return 'email';
  if (lowerCaseUrl.startsWith('tel:')) return 'phone';
  return 'location'; // Default to 'location' if no match
};
function Footer({ getSettingData, t }) {
  const navigate = useNavigate();
  const footerLogoUrl = getSettingData?.data?.home_setting_footer_logo_image || logo;
  const location = useLocation();

  const getText = (field, defaultValue, language) => {
    const data = getSettingData?.data?.[field];

    if (data) {
      // Check if the language exists in the data, otherwise fallback to a default language (e.g., 'en')
      return data[language] || data || defaultValue;
    }

    // If field or language doesn't exist, return the default value
    return defaultValue;
  };

  const onRedirect = (path) => {
    navigate(path);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // Smooth scroll effect
      });
    }, 300);
  };
  return (
    <div className="main overflow-hidden overflow-x-hidden footer">
      <Container fluid className="px-4">
        {/* Contact Information Section */}
        <Row className="pt-3 p-md-4 px-md-5 d-flex align-items-start">
          <Col md={9} lg={9} className="footer-div order-1 order-md-0">
            <Col lg={12} className="text-lg-start">
              {/* Contact Information - First Set */}
              <div className="mb-4">
                <p className="mb-2">
                  <a
                    href={`tel:${getText('home_setting_footer_call_icon_text', '+1234567890')}`}
                    target="_blank"
                    className="me-2 footericon"
                    rel="noopener noreferrer">
                    <FaPhone color="black" className="social-media-icon" />
                    <span className="footertext ms-2">
                      {getText('home_setting_footer_call_icon_text', 'Call us')}
                    </span>
                  </a>
                </p>
                <p className="mb-2">
                  <a
                    href={`mailto:${getText('home_setting_footer_email_address', 'info@triumphadventures.es')}`}
                    target="_blank"
                    className="me-2 footericon"
                    rel="noopener noreferrer">
                    <FaEnvelope color="black" className="social-media-icon" />
                    <span className="ms-2 footertext">
                      {getText('home_setting_footer_mail_icon_text', 'info@triumphadventures.es')}
                    </span>
                  </a>
                </p>
              </div>

              {/* Spacer between two sections */}
              <div className="my-3"></div>

              {/* Contact Information - Second Set */}
              <div>
                <p className="mb-2">
                  <svg
                    width="18"
                    height="18"
                    className="me-2 footericon2"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 0C5.24883 0 2.25 2.99883 2.25 6.75C2.25 8.04727 2.63672 9.04922 3.3082 10.0652L8.28984 17.6238C8.44102 17.8523 8.70117 18 9 18C9.29883 18 9.5625 17.8488 9.71016 17.6238L14.6918 10.0652C15.3633 9.04922 15.75 8.04727 15.75 6.75C15.75 2.99883 12.7512 0 9 0ZM9 10.4977C6.9293 10.4977 5.24883 8.81719 5.24883 6.74297C5.24883 4.66875 6.9293 2.98828 9 2.98828C11.0707 2.98828 12.7512 4.66875 12.7512 6.74297C12.7512 8.81719 11.0707 10.4977 9 10.4977Z"
                      fill="#CE1A2E"
                    />
                  </svg>
                  <span className="footertext2">
                    {getText(
                      `home_setting_footer_location_one_icon_text`,
                      'Malaga Office',
                      getCurrentLanguage()
                    )}
                  </span>
                </p>
                <p className="mb-2">
                  <svg
                    width="18"
                    height="18"
                    className="me-2 footericon2"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M9 0C5.24883 0 2.25 2.99883 2.25 6.75C2.25 8.04727 2.63672 9.04922 3.3082 10.0652L8.28984 17.6238C8.44102 17.8523 8.70117 18 9 18C9.29883 18 9.5625 17.8488 9.71016 17.6238L14.6918 10.0652C15.3633 9.04922 15.75 8.04727 15.75 6.75C15.75 2.99883 12.7512 0 9 0ZM9 10.4977C6.9293 10.4977 5.24883 8.81719 5.24883 6.74297C5.24883 4.66875 6.9293 2.98828 9 2.98828C11.0707 2.98828 12.7512 4.66875 12.7512 6.74297C12.7512 8.81719 11.0707 10.4977 9 10.4977Z"
                      fill="#CE1A2E"
                    />
                  </svg>
                  <span className="footertext2">
                    {getText(
                      `home_setting_footer_location_two_icon_text`,
                      'Malaga Office',
                      getCurrentLanguage()
                    )}
                  </span>
                </p>
              </div>
            </Col>
            <Col lg={12} className="d-flex justify-content-lg-start mt-4">
              <div className="d-flex gap-4">
                {['first', 'second', 'third', 'forth', 'fifth', 'six'].map((index) => {
                  const link =
                    getSettingData?.data?.[`home_setting_footer_${index}_icon_link`] || '#';
                  const linkType = getLinkType(link);
                  const IconComponent = iconMap[linkType];

                  return link !== '#' ? (
                    <a
                      key={index}
                      href={link}
                      target="_blank"
                      className="social-media-icon social"
                      rel="noopener noreferrer">
                      <IconComponent />
                    </a>
                  ) : null;
                })}
              </div>
            </Col>
          </Col>
          <Col md={3} lg={3} className="order-0 order-md-1">
            <Col
              lg={12}
              className="d-flex justify-content-start mb-3 mb-md-0 justify-md-content-center justify-content-lg-end">
              <div className="d-flex gap-3">
                <a href="/">
                  <img
                    src={footerLogoUrl}
                    alt="Triumph Logo"
                    className="d-d-inline-block logo-image footerlogo-image"
                  />
                </a>
              </div>
            </Col>
          </Col>
        </Row>

        {/* Links Section */}
        <Row className="pt-3 p-md-4 px-md-5 d-flex align-items-start">
          <Col lg={8} className="text-center text-lg-start">
            <Row>
              <Col md={5} lg={5} className=" text-lg-start">
                <h5 className="sub-heading-custom-list">
                  {t('front_page.footer_useful_information_heading')}
                </h5>
                <ul className="custom-list">
                  <li onClick={() => onRedirect('/page/terms-conditions')}>
                    <Link
                      to={`/page/terms-conditions`}
                      className={`footer-link ${
                        location.pathname === `/page/terms-conditions` ? 'footer-active-class' : ''
                      }`}>
                      <span> {t('front_page.footer_terms_label')}</span>
                    </Link>
                  </li>
                  <li onClick={() => onRedirect('/page/privacy-policy')}>
                    <Link
                      to={`/page/privacy-policy`}
                      className={`footer-link ${
                        location.pathname === `/page/privacy-policy` ? 'footer-active-class' : ''
                      }`}>
                      <span> {t('front_page.footer_privacy_label')} </span>
                    </Link>
                  </li>
                  <li>{t('front_page.footer_legal_notice')}</li>
                </ul>
              </Col>
              <Col md={5} lg={5} className=" text-lg-start mt-3 mt-md-0">
                <h5 className="sub-heading-custom-list">
                  {t('front_page.footer_about_us_heading')}
                </h5>
                <ul className="custom-list">
                  <li onClick={() => onRedirect('about')}>
                    <Link
                      to={`/about`}
                      className={`footer-link ${
                        location.pathname === `/about` ? 'footer-active-class' : ''
                      }`}>
                      <span>{t('front_page.footer_about')}</span>
                    </Link>
                  </li>
                  <li onClick={() => onRedirect('contact')}>
                    <Link
                      to={`/contact`}
                      className={`footer-link ${
                        location.pathname === `/contact` ? 'footer-active-class' : ''
                      }`}>
                      <span>{t('front_page.footer_contact')}</span>
                    </Link>
                  </li>
                  <li onClick={() => onRedirect('blog')}>
                    <Link
                      to={`/blog`}
                      className={`footer-link ${
                        location.pathname === `/blog` ||
                        location.pathname.startsWith(`/blog-details`)
                          ? 'footer-active-class'
                          : ''
                      }`}>
                      <span>{t('front_page.footer_blog')}</span>
                    </Link>
                  </li>
                  <li onClick={() => onRedirect('faq')}>
                    <Link
                      to={`/faq`}
                      className={`footer-link ${
                        location.pathname === `/faq` ? 'footer-active-class' : ''
                      }`}>
                      <span>{t('front_page.footer_faq')}</span>
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

// PropTypes validation
Footer.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default Footer;
