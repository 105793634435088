import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
// import googleMap from '../../assets/images/google-map.jpeg';
import Pin from '../../assets/images/red-dot.svg';
import { getCurrentLanguage } from 'helpers';

const OurOffices = ({ our_offices_data, t }) => {
  const containerStyle = {
    width: '100%',
    height: '388px',
    minHeight: '100%',
    objectFit: 'cover',
    textAlign: 'left',
    borderRadius: '10px',
  };

  const markers = [
    {
      position: {
        lat: Number(
          our_offices_data?.about_setting_about_our_offices_one_address_lat?.[
            `${getCurrentLanguage()}`
          ] || 36.658842
        ),
        lng: Number(
          our_offices_data?.about_setting_about_our_offices_one_address_long?.[
            `${getCurrentLanguage()}`
          ] || -4.578647
        ),
      },
      icon: Pin,
      title:
        our_offices_data?.about_setting_about_our_offices_one_address?.[`${getCurrentLanguage()}`],
    }, // Marker 1
    {
      position: {
        lat: Number(
          our_offices_data?.about_setting_about_our_offices_two_address_lat?.[
            `${getCurrentLanguage()}`
          ] || 41.337063
        ),
        lng: Number(
          our_offices_data?.about_setting_about_our_offices_two_address_long?.[
            `${getCurrentLanguage()}`
          ] || 1.999817
        ),
      },
      icon: Pin,
      title:
        our_offices_data?.about_setting_about_our_offices_two_address?.[`${getCurrentLanguage()}`],
    },
  ];

  const mapRef = useRef(null);
  const onLoad = (map) => {
    mapRef.current = map;

    // Create bounds and extend for each marker
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => {
      bounds.extend(marker.position);
    });

    // Fit the map to the bounds
    map.fitBounds(bounds);
  };

  // const center = {
  //   lat: Number(our_offices_data?.about_setting_about_our_offices_one_address_lat || 36.658842),
  //   lng: Number(our_offices_data?.about_setting_about_our_offices_one_address_long || -4.578647),
  // };

  // const markerOne = {
  //   position: {
  //     lat: Number(our_offices_data?.about_setting_about_our_offices_one_address_lat || 36.658842),
  //     lng: Number(our_offices_data?.about_setting_about_our_offices_one_address_long || -4.578647),
  //   },
  //   icon: Pin,
  // };

  // const markerTwo = {
  //   position: {
  //     lat: Number(our_offices_data?.about_setting_about_our_offices_two_address_lat || 41.337063),
  //     lng: Number(our_offices_data?.about_setting_about_our_offices_two_address_long || 1.999817),
  //   },
  //   icon: Pin,
  // };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  return (
    <Container fluid className="pb-4 pb-xxl-5">
      <div className="our-offices px-xxl-5">
        <Row className="p-2">
          <Col lg={12} className="">
            <h1 className="our-offices-title">
              {our_offices_data?.about_setting_about_our_offices_header_title?.[
                `${getCurrentLanguage()}`
              ] || our_offices_data?.about_setting_about_our_offices_header_title}
            </h1>
          </Col>
        </Row>
        <Row className="p-2 row-gap-4">
          <Col lg={7}>
            {/* Google Map */}
            {/* <img src={googleMap} alt="Main Display" className="img-fluid our-offices-image" /> */}
            {/* Google Map */}
            {isLoaded && (
              <>
                <div className="our-mission-image">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    // center={center}
                    // zoom={10}
                    onLoad={onLoad}
                    options={{ mapTypeControl: false }}>
                    {markers.map((marker, index) => (
                      <Marker
                        key={`marker-${index}`}
                        position={marker.position}
                        icon={{
                          url: marker.icon,
                          scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                        }}
                        title={marker.title}
                      />
                    ))}
                    {/* <Marker
                      key="marker-1"
                      position={markerOne.position}
                      icon={{
                        url: markerOne.icon,
                        scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                      }}
                    />
                    <Marker
                      key="marker-2"
                      position={markerTwo.position}
                      icon={{
                        url: markerTwo.icon,
                        scaledSize: new window.google.maps.Size(30, 40), // Adjust as needed
                      }}
                    /> */}
                  </GoogleMap>
                </div>
              </>
            )}
          </Col>
          <Col
            lg={5}
            className="d-flex flex-column flex-md-row flex-lg-column justify-content-between">
            <div className="our-offices-location-div">
              <h5 className="text-title">
                {our_offices_data?.about_setting_about_our_offices_one_title?.[
                  `${getCurrentLanguage()}`
                ] || our_offices_data?.about_setting_about_our_offices_one_title}
              </h5>
              <p className="text-desc">
                {our_offices_data?.about_setting_about_our_offices_one_address?.[
                  `${getCurrentLanguage()}`
                ] || our_offices_data?.about_setting_about_our_offices_one_address}
              </p>
              <p className="text-desc d-flex align-items-center">
                <svg
                  className="me-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.625 17.5C14.8889 17.5 13.1703 17.125 11.4692 16.375C9.76805 15.625 8.22278 14.5556 6.83333 13.1667C5.44389 11.7778 4.37444 10.2361 3.625 8.54167C2.87556 6.84722 2.50056 5.125 2.5 3.375V2.5H7.41667L8.1875 6.6875L5.8125 9.08333C6.11806 9.625 6.45833 10.1389 6.83333 10.625C7.20833 11.1111 7.61111 11.5625 8.04167 11.9792C8.44444 12.3819 8.88556 12.7675 9.365 13.1358C9.84444 13.5042 10.3617 13.8478 10.9167 14.1667L13.3333 11.75L17.5 12.6042V17.5H16.625Z"
                    fill="#333333"
                  />
                </svg>
                <a
                  href={`tel:${our_offices_data?.about_setting_about_our_offices_one_phone}`}
                  className="text-phone">
                  {our_offices_data?.about_setting_about_our_offices_one_phone}
                </a>
              </p>
              <p className="text-desc d-flex align-items-center">
                <svg
                  className="me-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_242_3052)">
                    <path
                      d="M17.9611 3.33324C17.8854 3.32544 17.809 3.32544 17.7333 3.33324H2.17775C2.07805 3.33478 1.97901 3.34973 1.8833 3.37769L9.91108 11.3721L17.9611 3.33324Z"
                      fill="#333333"
                    />
                    <path
                      d="M18.7834 4.10547L10.6945 12.161C10.4863 12.368 10.2047 12.4841 9.91115 12.4841C9.61761 12.4841 9.336 12.368 9.12782 12.161L1.11115 4.16658C1.08651 4.25716 1.07344 4.35049 1.07227 4.44436V15.5555C1.07227 15.8502 1.18933 16.1328 1.3977 16.3411C1.60608 16.5495 1.88869 16.6666 2.18338 16.6666H17.7389C18.0336 16.6666 18.3162 16.5495 18.5246 16.3411C18.733 16.1328 18.85 15.8502 18.85 15.5555V4.44436C18.8456 4.32861 18.8231 4.21427 18.7834 4.10547ZM2.94449 15.5555H2.17227V14.761L6.21115 10.7555L6.99449 11.5388L2.94449 15.5555ZM17.7278 15.5555H16.95L12.9 11.5388L13.6834 10.7555L17.7223 14.761L17.7278 15.5555Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_242_3052">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a
                  href={`mailto:${our_offices_data?.about_setting_about_our_offices_one_email}`}
                  className="text-email"
                  target="_blank"
                  rel="noopener noreferrer">
                  {our_offices_data?.about_setting_about_our_offices_one_email}
                </a>
              </p>
            </div>
            <div className="our-offices-location-div">
              <h5 className="text-title">
                {our_offices_data?.about_setting_about_our_offices_two_title?.[
                  `${getCurrentLanguage()}`
                ] || our_offices_data?.about_setting_about_our_offices_two_title}
              </h5>
              <p className="text-desc">
                {
                  our_offices_data?.about_setting_about_our_offices_two_address?.[
                    `${getCurrentLanguage()}`
                  ]
                }
              </p>
              <p className="text-desc d-flex align-items-center">
                <svg
                  className="me-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.625 17.5C14.8889 17.5 13.1703 17.125 11.4692 16.375C9.76805 15.625 8.22278 14.5556 6.83333 13.1667C5.44389 11.7778 4.37444 10.2361 3.625 8.54167C2.87556 6.84722 2.50056 5.125 2.5 3.375V2.5H7.41667L8.1875 6.6875L5.8125 9.08333C6.11806 9.625 6.45833 10.1389 6.83333 10.625C7.20833 11.1111 7.61111 11.5625 8.04167 11.9792C8.44444 12.3819 8.88556 12.7675 9.365 13.1358C9.84444 13.5042 10.3617 13.8478 10.9167 14.1667L13.3333 11.75L17.5 12.6042V17.5H16.625Z"
                    fill="#333333"
                  />
                </svg>
                <a
                  href={`tel:${our_offices_data?.about_setting_about_our_offices_two_phone}`}
                  className="text-phone">
                  {our_offices_data?.about_setting_about_our_offices_two_phone}
                </a>
              </p>
              <p className="text-desc d-flex align-items-center">
                <svg
                  className="me-2"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_242_3052)">
                    <path
                      d="M17.9611 3.33324C17.8854 3.32544 17.809 3.32544 17.7333 3.33324H2.17775C2.07805 3.33478 1.97901 3.34973 1.8833 3.37769L9.91108 11.3721L17.9611 3.33324Z"
                      fill="#333333"
                    />
                    <path
                      d="M18.7834 4.10547L10.6945 12.161C10.4863 12.368 10.2047 12.4841 9.91115 12.4841C9.61761 12.4841 9.336 12.368 9.12782 12.161L1.11115 4.16658C1.08651 4.25716 1.07344 4.35049 1.07227 4.44436V15.5555C1.07227 15.8502 1.18933 16.1328 1.3977 16.3411C1.60608 16.5495 1.88869 16.6666 2.18338 16.6666H17.7389C18.0336 16.6666 18.3162 16.5495 18.5246 16.3411C18.733 16.1328 18.85 15.8502 18.85 15.5555V4.44436C18.8456 4.32861 18.8231 4.21427 18.7834 4.10547ZM2.94449 15.5555H2.17227V14.761L6.21115 10.7555L6.99449 11.5388L2.94449 15.5555ZM17.7278 15.5555H16.95L12.9 11.5388L13.6834 10.7555L17.7223 14.761L17.7278 15.5555Z"
                      fill="#333333"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_242_3052">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <a
                  href={`mailto:${our_offices_data?.about_setting_about_our_offices_two_email}`}
                  className="text-email"
                  target="_blank"
                  rel="noopener noreferrer">
                  {our_offices_data?.about_setting_about_our_offices_two_email}
                </a>
              </p>
            </div>

            {/* Google Map */}
          </Col>
        </Row>
      </div>
    </Container>
  );
};
OurOffices.propTypes = {
  our_offices_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default OurOffices;
