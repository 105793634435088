import client from '../libs/HttpClient';
class SettingService {
  static getSettingData(params) {
    return client.get('setting/get?params=' + params);
  }
  static getServiceData() {
    return client.get('setting/get-services');
  }
  static getReviewData() {
    return client.get('setting/get-reviews');
  }
  static getCMSData(params) {
    return client.get('cms/get-cms?params=' + params);
  }
  static getOurTeamData() {
    return client.get('setting/get-our-teams');
  }
  static getBlogLatestPostData(params) {
    return client.get('blog/get-blog-latest-posts', { params });
  }
  static getBlogDetail(params) {
    return client.get('blog/get-blog-description', { params });
  }
  static getBlogCategoryData() {
    return client.get('blog/get-blog-categories');
  }
  static getFaqData() {
    return client.get('setting/get-faq');
  }

  static getSearchFaqData(params) {
    return client.get('setting/get-search-faq', { params });
  }
  static contactInformationSent(request) {
    return client.post('setting/sent-contact-information', request);
  }

  static getCountryData() {
    return client.get('setting/get-country');
  }
}
export { SettingService };
