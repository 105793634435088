import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import OurMissionImage from '../../assets/images/our_mission.png';
import { getCurrentLanguage } from 'helpers';

const OurMission = ({ our_mission_data, t }) => {
  return (
    <>
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center text-center">
        <div className="our-mission">
          <Row>
            <Col lg={6} xl={5} className="position-relative ps-lg-0 order-1 order-lg-0">
              {/* Main Image */}
              <img
                src={our_mission_data?.about_setting_about_our_mission_image || OurMissionImage}
                alt="Main Display"
                className="img-fluid our-mission-image"
              />
              {/* Main Image */}
            </Col>
            {/* Content */}
            <Col
              lg={6}
              xl={7}
              className="position-relative d-flex flex-column justify-content-center order-0 order-lg-1 data-div pe-lg-4">
              <h1 className="our-mission-name">
                {our_mission_data?.about_setting_about_our_mission_title[
                  `${getCurrentLanguage()}`
                ] || our_mission_data?.about_setting_about_our_mission_title}
              </h1>
              {/* <p className="our-mission-description mt-3">
                {our_mission_data?.about_setting_about_our_mission_description}
              </p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    our_mission_data?.about_setting_about_our_mission_description[
                      `${getCurrentLanguage()}`
                    ] || our_mission_data?.about_setting_about_our_mission_description,
                }}
                className="our-mission-description mt-3"
              />
            </Col>
            {/* Content */}
          </Row>
        </div>
      </Container>
    </>
  );
};
OurMission.propTypes = {
  our_mission_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default OurMission;
