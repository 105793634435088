import { useMutation, useQuery } from 'react-query';
import { SettingService } from '../api/';
import { toast } from 'react-toastify';

const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetCMSData = (params, onSuccess, onError) => {
  return useQuery(
    ['cms_setting_data', params],
    () => {
      return SettingService.getCMSData(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetSettingData = (params, onSuccess, onError) => {
  return useQuery(
    ['setting_data', params],
    () => {
      return SettingService.getSettingData(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetServiceData = (onSuccess, onError) => {
  return useQuery(
    ['service_data'],
    () => {
      return SettingService.getServiceData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetReviewData = (onSuccess, onError) => {
  return useQuery(
    ['review_data'],
    () => {
      return SettingService.getReviewData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetOurTeamData = (onSuccess, onError) => {
  return useQuery(
    ['our_team_data'],
    () => {
      return SettingService.getOurTeamData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetBlogCategoryData = (onSuccess, onError) => {
  return useQuery(
    ['blog_category_data'],
    () => {
      return SettingService.getBlogCategoryData();
    },
    {
      onSuccess,
      onError,
    }
  );
};
const useGetBlogLatestPostData = (params, onSuccess, onError) => {
  return useQuery(
    ['latest_blog_data', params],
    () => {
      // Make sure to spread the params into the request
      return SettingService.getBlogLatestPostData(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetBlogDetail = (params, onSuccess, onError) => {
  return useQuery(
    ['blog_detail_data', params],
    () => {
      // Make sure to spread the params into the request
      return SettingService.getBlogDetail(params);
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetFaqData = (onSuccess, onError) => {
  return useQuery(
    ['faq_data'],
    () => {
      return SettingService.getFaqData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetSearchFaqData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    [`all-search_faq_data-list-view`, [params]],
    () => SettingService.getSearchFaqData(params),
    {
      onSuccess,
      onError,
      enabled: params.is_enabled,
    }
  );
};

const useContactInformationSent = (onSuccess, onError = onDefaultError) => {
  return useMutation(SettingService.contactInformationSent, {
    onSuccess,
    onError,
  });
};

const useGetCountryData = (params, onSuccess, onError = onDefaultError) => {
  return useQuery([`country_data`, [params]], () => SettingService.getCountryData(params), {
    onSuccess,
    onError,
  });
};

export {
  useGetCMSData,
  useGetSettingData,
  useGetServiceData,
  useGetReviewData,
  useGetOurTeamData,
  useGetBlogCategoryData,
  useGetBlogLatestPostData,
  useGetBlogDetail,
  useGetFaqData,
  useGetSearchFaqData,
  useContactInformationSent,
  useGetCountryData,
};
