import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../assets/scss/page/_blog.scss';
import { Row, Col, Container, Button, Spinner } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { FaClock } from 'react-icons/fa6';
import ContactUsIcon from '../../assets/images/contact-us.svg';
import { useGetBlogCategoryData, useGetBlogLatestPostData, useGetBlogDetail } from 'hooks';
import { Helmet } from 'react-helmet';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dateUtils';
import { truncateText } from '../../utils/textUtils';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showLoader, hideLoader } from 'store/features/loaderSlice';
import { getCurrentLanguage } from '../../helpers/';

const Main = ({ getSettingData, t }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  // Parse the query string to get the `categoryId`
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get('categoryId');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0); // State to track the active tab
  const [tabs, setTabs] = useState([]); // Dynamic tabs state
  const [currentPage, setCurrentPage] = useState(1); // Track the current page of posts
  const [hasNextPage, setHasNextPage] = useState(true); // Track if there's a next page of posts
  const [latestPosts, setLatestPosts] = useState([]); // Store the latest posts
  const [blogDetailId, setBlogDetailId] = useState(null); // ID of the blog detail to exclude
  const [searchValue, setSearchValue] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Show loader when component mounts
    dispatch(showLoader());
    setIsLoading(true);
  }, [dispatch]);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    // If the length is less than 3, reset everything
    if (value.length >= 3) {
      dispatch(showLoader());
      setIsSearched(true);
      setCurrentPage(1);
      setLatestPosts([]);
      setHasNextPage(true);
    } else {
      setIsSearched(false);
      setLatestPosts([]);
      setHasNextPage(false);
    }
  };
  // Loading states
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [isLoadingMainPost, setIsLoadingMainPost] = useState(true);
  const [isLoadingLatestPosts, setIsLoadingLatestPosts] = useState(false);

  const handleTabClick = (index) => {
    dispatch(showLoader());
    setActiveTab(index); // Update active tab on click
    setCurrentPage(1); // Reset to page 1 when switching tabs
    setLatestPosts([]); // Clear previous posts when changing category
    setHasNextPage(true);
    setBlogDetailId(null);
  };
  const handlePostClick = (postId) => {
    // Navigate to the new page with the post id
    navigate(`/blog-details/${postId}`);
  };
  // Fetch Blog Categories
  const { data: getBlogCategoryData, isSuccess: isBlogCategorySuccess } = useGetBlogCategoryData(
    () => setIsLoadingCategories(false),
    (error) => {
      console.error('Error fetching categories:', error.message);
      setIsLoadingCategories(false);
    }
  );
  // Set tabs once the category data is fetched
  useEffect(() => {
    if (getBlogCategoryData?.data?.blog_category_list) {
      const categories = getBlogCategoryData.data.blog_category_list;
      setTabs(categories);

      // Find the index of the category ID from the URL
      const selectedTabIndex = categoryId
        ? categories.findIndex((cat) => cat._id === categoryId)
        : 0; // Default to the first category if no ID is provided

      setActiveTab(selectedTabIndex === -1 ? 0 : selectedTabIndex); // Fallback to the first category if ID is invalid
    }
  }, [getBlogCategoryData, categoryId]);

  const blogCategoryId = tabs[activeTab]?._id;
  // Fetch Blog Details
  const { data: getBlogDetailData, isSuccess: isBlogDetailsSuccess } = useGetBlogDetail(
    { blog_category_id: blogCategoryId },
    () => setIsLoadingMainPost(false),
    (error) => {
      console.error('Error fetching main post:', error.message);
      setIsLoadingMainPost(false);
    }
  );

  // Set the blogDetailId once fetched
  useEffect(() => {
    if (getBlogDetailData?.data?._id) {
      setBlogDetailId(getBlogDetailData.data?._id);
    }
  }, [getBlogDetailData]);
  // Fetch Latest Posts
  const { data: getLatestPostsData, isSuccess: isLatestPostDetailSuccess } =
    useGetBlogLatestPostData(
      searchValue && searchValue.length >= 3
        ? {
            search_query: searchValue, // Use search query when length is >= 3
          }
        : blogCategoryId && currentPage && blogDetailId
          ? {
              blog_category_id: blogCategoryId, // Use blog category, page, and exclude_id when available
              page: currentPage,
              exclude_id: blogDetailId,
            }
          : {}, // If any parameter is missing, pass an empty object (no data to send)
      () => {
        if (
          (blogCategoryId && currentPage && blogDetailId) ||
          (searchValue && searchValue.length >= 3)
        ) {
          setIsLoading(false);
          dispatch(hideLoader());
          setIsLoadingLatestPosts(false);
        }
      },
      (error) => {
        console.error('Error fetching latest posts:', error.message);
        setIsLoadingLatestPosts(false);
      }
    );

  // Handle appending new posts when "Read More" is clicked
  useEffect(() => {
    if (getLatestPostsData?.data?.blogs) {
      setLatestPosts((prevPosts) => [...prevPosts, ...getLatestPostsData.data.blogs]);
      setHasNextPage(getLatestPostsData?.data?.pagination?.hasNextPage);
    }
  }, [getLatestPostsData]);

  const handleLoadMore = () => {
    if (hasNextPage) {
      setIsLoadingLatestPosts(true);
      setCurrentPage((prevPage) => prevPage + 1); // Increment page
    }
  };
  const pageTitle =
    getBlogCategoryData?.data?.blog_category_list[activeTab]?.seo_title[
      `${getCurrentLanguage()}`
    ] || 'Triumph';
  const seoTitle =
    getBlogCategoryData?.data?.blog_category_list[activeTab]?.seo_title[`${getCurrentLanguage()}`];
  const seoDescription =
    getBlogCategoryData?.data?.blog_category_list[activeTab]?.seo_description[
      `${getCurrentLanguage()}`
    ];
  useEffect(() => {
    // Close loader when all data is successfully fetched
    if (isBlogCategorySuccess && isBlogDetailsSuccess && isLatestPostDetailSuccess) {
      if (
        (blogCategoryId && currentPage && blogDetailId) ||
        (searchValue && searchValue.length >= 3)
      ) {
        setIsLoading(false);
        dispatch(hideLoader());
      }
    }
  }, [isBlogCategorySuccess, isBlogDetailsSuccess, isLatestPostDetailSuccess, dispatch]);
  return (
    <>
      {isLoading ? (
        <p>Loading...</p> // Placeholder for loader
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="title" content={seoTitle} />
            <meta name="description" content={seoDescription} />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:description" content={seoDescription} />
          </Helmet>
          {getSettingData && (
            <Container fluid className="blog-container-main">
              <Row className="blog-header">
                <Col md={6} className="text-start ps-0 order-1 order-md-0">
                  {!isSearched && <h1 className="blog-title m-0">{t('front_page.blog_title')}</h1>}
                </Col>
                <Col md={6} className="pe-0 search-box order-0 order-md-1">
                  <div className="search-container d-flex justify-content-end">
                    <div className="search-bar">
                      <input
                        type="text"
                        placeholder={t('front_page.blog_search_placeholder')}
                        className="search-input"
                        onChange={handleSearch}
                      />
                      <div className="search-icon">
                        <FaSearch />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {!isSearched && (
                <>
                  {/* Tabs */}
                  <Row className="tabs-container mt-3">
                    {isLoadingCategories ? (
                      <Spinner animation="border" />
                    ) : tabs.length === 0 ? (
                      <Col className="text-center">{t('front_page.blog_no_category_found')}</Col>
                    ) : (
                      tabs.map((tab, index) => (
                        <Col
                          key={index}
                          className={`tab-item ${activeTab === index ? 'active-tab' : ''} custom-tabs justify-content-center`}
                          onClick={() => handleTabClick(index)}>
                          {tab.category_name[`${getCurrentLanguage()}`]}{' '}
                          {/* Assuming 'name' is the property for the tab name */}
                        </Col>
                      ))
                    )}
                  </Row>

                  {/* Display Latest Blog Posts */}
                  <Row className=" mt-4">
                    {isLoadingMainPost ? (
                      <Spinner animation="border" />
                    ) : getBlogDetailData?.data ? (
                      <>
                        <Col
                          md={6}
                          lg={5}
                          className="p-0"
                          onClick={() => handlePostClick(getBlogDetailData?.data?.slug)}>
                          <img
                            loading="lazy"
                            src={getBlogDetailData?.data?.attachments?.[0]}
                            alt="blog"
                            className="main-blog-img"
                          />
                        </Col>
                        <Col md={6} lg={7} className="p-md-4 pt-3">
                          <button className="blog-tag">
                            {getBlogDetailData?.data?.category?.category_name[
                              `${getCurrentLanguage()}`
                            ] || 'Category'}
                          </button>
                          <h1
                            className="blog-main-heading"
                            onClick={() => handlePostClick(getBlogDetailData?.data?.slug)}>
                            {getBlogDetailData?.data?.title[`${getCurrentLanguage()}`] ||
                              'Default Blog Title'}
                          </h1>
                          <span className="blog-date-time">
                            <FaClock /> {formatDate(getBlogDetailData?.data?.updated_at)}
                          </span>
                          <p
                            className="blog-para"
                            dangerouslySetInnerHTML={{
                              __html: truncateText(
                                getBlogDetailData?.data?.description[`${getCurrentLanguage()}`],
                                300
                              ),
                            }}></p>
                          <Link
                            to={`/blog-details/` + getBlogDetailData?.data?.slug}
                            className={`btn btn-primary blog-readmore-btn`}>
                            <span> {t('front_page.blog_read_more_btn')} </span>
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <Col className="text-center">
                        {/* {t('front_page.blog_no_main_post_found')} */}
                      </Col>
                    )}
                  </Row>
                </>
              )}
              {/* Latest Posts Grid */}
              <Row className=" mt-3 justify-content-between row-gap-3">
                <Col md={12} className="px-0">
                  <h1 className="latest-post-title">
                    {isSearched
                      ? t('front_page.blog_search_results')
                      : t('front_page.blog_latest_posts')}
                  </h1>
                </Col>
                {!isLoadingLatestPosts &&
                  latestPosts &&
                  latestPosts.map((post, index) => (
                    <div className="mb-4 p-0 mt-3 latest-blog-single" key={index}>
                      <div
                        className="card inner-part h-100 "
                        onClick={() => handlePostClick(post.slug)}>
                        <div className="post-img">
                          <a href="#" alt="latest-post-img" className="latest-post-img">
                            <img
                              loading="lazy"
                              src={post?.attachments?.[0]}
                              className="img-fluid"
                              alt="home-sell"
                              width="100%"
                              height="100%"
                            />
                          </a>
                        </div>
                        <div className="card-body inner-body">
                          <button className="blog-latest-tag">
                            {post.category?.category_name[`${getCurrentLanguage()}`]}
                          </button>
                          <p className="blog-latest-para-1">
                            {post.title[`${getCurrentLanguage()}`]}
                          </p>
                          <p
                            className="blog-latest-para-2"
                            dangerouslySetInnerHTML={{
                              __html: truncateText(post.description[`${getCurrentLanguage()}`], 75),
                            }}></p>
                          <span className="blog-latest-date-time">
                            <FaClock /> {formatDate(post.updated_at)}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
              </Row>
              <Row>
                <Col md={12} className="text-center">
                  <Button
                    className="btn btn-primary blog-readmore-btn"
                    onClick={handleLoadMore}
                    disabled={!hasNextPage || isLoadingLatestPosts}>
                    {isLoadingLatestPosts ? (
                      <Spinner animation="border" size="sm" />
                    ) : hasNextPage ? (
                      t('front_page.blog_load_more_btn')
                    ) : (
                      t('front_page.blog_no_more_posts')
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          )}
          <Container fluid className="footer-append-div row-gap-3">
            <Col xs={12} sm={2} className="text-center">
              <img src={ContactUsIcon} alt="contact-icon" />
            </Col>
            <Col xs={12} sm={7} className="text-sm-left">
              <p>{t('front_page.footer_add_on_contact_us_description')}</p>
            </Col>
            <Col xs={12} sm={3} className="text-center">
              <Button className="contact-us-btn">
                <NavLink to={'/contact'} className="Link">
                  {t('front_page.footer_add_on_contact_us_btn_text')}
                </NavLink>
              </Button>
            </Col>
          </Container>
        </>
      )}
    </>
  );
};

Main.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Main;
