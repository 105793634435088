import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PublicRoute from './PublicRoute';
import Main from '../pages/HomePage/Main';
import Contact from '../pages/ContactPage/Main';
import About from '../pages/AboutPage/Main';
import Blog from '../pages/BlogPage/Main';
import Faq from '../pages//FaqPage/Main';
import ErrorPage from '../pages/Errorpage/ErrorPage';
import CMS from '../pages/CMS/CMS';
import { useGetSettingData } from '../hooks';
import BlogDetails from 'pages/BlogPage/BlogDetails';
import { getCurrentLanguage } from '../helpers/';
const PagesRoutes = ({ t }) => {
  const location = useLocation();

  // Determine route name based on location.pathname
  const routeName =
    location.pathname === '/' ||
    location.pathname.startsWith('/page/') ||
    location.pathname.startsWith('/blog-details/') ||
    location.pathname.startsWith('/company')
      ? 'home'
      : location.pathname.substr(1);

  const { data: getSettingData } = useGetSettingData(
    routeName,
    () => {},
    (error) => {
      if (error.data.redirect === true) {
        console.log('Error occurred: ', error.message);
      }
    }
  );

  var pageTitle =
    getSettingData?.data?.home_setting_seo_title?.[`${getCurrentLanguage()}`] || 'Triumph';
  var seoTitle =
    getSettingData?.data?.home_setting_seo_title?.[`${getCurrentLanguage()}`] || 'Triumph';
  var seoDescription =
    getSettingData?.data?.home_setting_seo_description?.[`${getCurrentLanguage()}`] || 'Triumph';
  if (location.pathname === '/rentals') {
    pageTitle = t('front_page.header_nav_rentals');
    seoTitle =
      getSettingData?.data?.faq_setting_seo_title?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_rentals');
    seoDescription =
      getSettingData?.data?.faq_setting_seo_description?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_rentals');
  } else if (location.pathname === '/about') {
    pageTitle =
      getSettingData?.data?.about_setting_seo_title?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_about');
    seoTitle =
      getSettingData?.data?.about_setting_seo_title?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_about');
    seoDescription =
      getSettingData?.data?.about_setting_seo_description?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_about');
  } else if (location.pathname === '/contact') {
    pageTitle =
      getSettingData?.data?.contact_setting_contact_seo_title?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_contact');
    seoTitle =
      getSettingData?.data?.contact_setting_contact_seo_title?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_contact');
    seoDescription =
      getSettingData?.data?.contact_setting_contact_seo_description?.[`${getCurrentLanguage()}`] ||
      t('front_page.header_nav_contact');
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} />
        <link
          rel="icon"
          type="image/x-icon"
          href={`${getSettingData?.data?.home_page_general_favicon_logo}`}
        />
      </Helmet>
      <Routes>
        <Route element={<PublicRoute getSettingData={getSettingData} t={t} />}>
          <Route exact path="/" element={<Main getSettingData={getSettingData} t={t} />} />
          <Route exact path="/about" element={<About getSettingData={getSettingData} t={t} />} />
          <Route
            exact
            path="/contact"
            element={<Contact getSettingData={getSettingData} t={t} />}
          />
          <Route exact path="/blog" element={<Blog getSettingData={getSettingData} t={t} />} />
          <Route exact path="/page/:slug" element={<CMS t={t} />} />
          <Route
            exact
            path="/blog-details/:slug"
            element={<BlogDetails getSettingData={getSettingData} t={t} />}
          />
          <Route exact path="/faq" element={<Faq t={t} />} />
        </Route>
        <Route>
          <Route path="*" element={<ErrorPage t={t} />} />
        </Route>
      </Routes>
    </>
  );
};

PagesRoutes.propTypes = {
  t: PropTypes.func,
};

export default PagesRoutes;
