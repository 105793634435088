import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { useGetOurTeamData } from '../../hooks';
import TeamImage from '../../assets/images/team1.png';
import { getCurrentLanguage } from 'helpers';

const OurTeam = ({ our_team_data, t }) => {
  const [ourTeam, setOurTeam] = useState([]);
  const { isFetching } = useGetOurTeamData(
    (res) => {
      setOurTeam(res?.data);
    },
    (error) => {
      if (error.data.redirect === true) {
        console.log('Error occurred: ', error.message);
      }
    }
  );
  return (
    <>
      <Container fluid className="container-fluid">
        <div className="our-team mt-2 mb-2">
          <Row className="px-xxl-5">
            <Col lg={12} className="px-3 pb-xxl-4">
              <h1 className="our-team-title">
                {our_team_data?.about_setting_about_our_team_title?.[`${getCurrentLanguage()}`] ||
                  our_team_data?.about_setting_about_our_team_title}
              </h1>
              <p className="our-team-description">
                {our_team_data?.about_setting_about_our_team_description?.[
                  `${getCurrentLanguage()}`
                ] || our_team_data?.about_setting_about_our_team_description}
              </p>
            </Col>
          </Row>
          <Row className="team-member-wrapper">
            {ourTeam &&
              !isFetching &&
              ourTeam.map((team) => {
                return (
                  <>
                    <Col md={6} lg={3} className="d-flex justify-content-center">
                      <div className="team-member-div position-relative">
                        {/* Main Image */}
                        <div className="our-team-image overflow-hidden">
                          <img
                            src={team.ourTeamImage || TeamImage}
                            alt="Main Display"
                            className="img-fluid "
                          />
                        </div>
                        {/* Main Image */}
                        {/* <Row className="justify-content-center">
                          <Col xs="auto p-0"> */}
                        <div className="our-team-description-div">
                          <span className="d-flex align-items-center gap-1">
                            <h5 className="team-name">
                              {team?.first_name?.[`${getCurrentLanguage()}`] || ''}
                            </h5>
                            <h5 className="team-name">
                              {team?.last_name?.[`${getCurrentLanguage()}`] || ''}
                            </h5>
                          </span>
                          <p className="team-designation">
                            {team?.designation?.[`${getCurrentLanguage()}`]}
                          </p>
                          <p className="team-email">
                            <a
                              href={`mailto:${team?.email}`}
                              className="text-email"
                              target="_blank"
                              rel="noopener noreferrer">
                              {team?.email}
                            </a>
                          </p>
                        </div>
                        {/* </Col>
                        </Row> */}
                      </div>
                    </Col>
                  </>
                );
              })}
          </Row>
        </div>
      </Container>
    </>
  );
};
OurTeam.propTypes = {
  our_team_data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default OurTeam;
