import React from 'react';
import PropTypes from 'prop-types';
import { default as Header } from '../Header/Header';
import { default as Footer } from '../Footer/Footer';
const PublicLayout = ({ getSettingData, children, t }) => {
  return (
    <div>
      <Header getSettingData={getSettingData} t={t} />
      {children}
      <Footer getSettingData={getSettingData} t={t} />
    </div>
  );
};

PublicLayout.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  t: PropTypes.func,
};

export { PublicLayout };
