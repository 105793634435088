import React from 'react';
import PropTypes from 'prop-types';
// import '../../assets/css/style.css'; // Your custom CSS
import '../../assets/scss/page/_about.scss';
import Banner from './Banner';
import OurMission from './OurMission';
import OurVision from './OurVision';
import OurTeam from './OurTeam';
import OurOffices from './OurOffices';
import { LoadingShimmer } from '../../common/shimmer';

const Main = ({ getSettingData, t }) => {
  return (
    <>
      {getSettingData ? (
        <div className="about-container-main">
          {/** Banner Section **/}
          <Banner our_banner_data={getSettingData?.data} t={t} />
          {/** Banner Section **/}

          {/** OurMission Section **/}
          <OurMission our_mission_data={getSettingData?.data} t={t} />
          {/** OurMission Section **/}

          {/** OurVision Section **/}
          <OurVision our_vision_data={getSettingData?.data} t={t} />
          {/** OurVision Section **/}

          <div className="our-team-office-div">
            {/** OurTeam Section **/}
            <OurTeam our_team_data={getSettingData?.data} t={t} />
            {/** OurTeam Section **/}

            {/** OurOffices Section **/}
            <OurOffices our_offices_data={getSettingData?.data} t={t} />
            {/** OurOffices Section **/}
          </div>
        </div>
      ) : (
        <>
          <div className="text-center mt-3 mb-3">
            <LoadingShimmer />
          </div>
        </>
      )}
    </>
  );
};
Main.propTypes = {
  getSettingData: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired, // Assuming t is a function
};

export default Main;
