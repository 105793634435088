import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AuthSlice from './features/authSlice';
import loaderReducer from './features/loaderSlice';
import settingReducer from './features/settingSlice';
import { thunk } from 'redux-thunk';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'triumph-web',
  storage,
  blacklist: [],
};
const rootReducer = combineReducers({
  user: AuthSlice,
  loader: loaderReducer,
  setting: settingReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
  devTools: { trace: true, traceLimit: 1 },
});
let persistor = persistStore(store);
export { persistor, store };
