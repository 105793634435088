import React, { Suspense, useEffect } from 'react';
import $ from 'jquery';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import PagesRoutes from '../routes';
import { persistor, store } from '../store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/style.css';
import { setAuthToken, setAuthType } from '../libs/HttpClient';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      //retryOnMount:
      retry: false,
    },
  },
});

function App() {
  useEffect(() => {
    // Ensure jQuery is being used if needed
    if ($) {
      console.log('jQuery is loaded in app js', $);
    } else {
      console.log('jQuery is not loaded');
    }
  });
  const { t } = useTranslation();

  /*if (!navigator.onLine) {
    return <NoInternet />;
  }

  window.onoffline = (event) => {
    return <NoInternet />;
  };*/

  window.ononline = (event) => {
    window.location.reload(true);
  };

  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
    setAuthType('admin');
  };

  return (
    <Suspense>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={handleOnBeforeLift}>
          <QueryClientProvider client={queryClient}>
            <PagesRoutes t={t} />
            <ToastContainer />
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </Suspense>
  );
}
export default App;
