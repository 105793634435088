import React, { useEffect, useState } from 'react';
import quoteImg from '../../assets/images/quote.svg';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useGetReviewData } from 'hooks';
import { useDispatch } from 'react-redux';
// import { showLoader } from 'store/features/loaderSlice';
import OwlCarousel from 'react-owl-carousel2';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { getCurrentLanguage } from '../../helpers/';
import PropTypes from 'prop-types';

function Review({ t }) {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Show loader when component mounts
    // dispatch(showLoader());
    setIsLoading(true);
  }, [dispatch]);

  const { data: getReviewData, isSuccess: isReviewSuccess } = useGetReviewData(
    () => {},
    (error) => {
      console.log('In this api error occurs ' + error.message);
      setIsLoading(false);
    }
  );

  const reviews = getReviewData?.data || [];

  useEffect(() => {
    // Close loader when all data is successfully fetched
    if (isReviewSuccess) {
      setIsLoading(false);
      // dispatch(hideLoader());
    }
  }, [isReviewSuccess, dispatch]);

  useEffect(() => {
    // Show loader if data fetching fails
    if (isReviewSuccess) {
      setIsLoading(false);
      // dispatch(showLoader());
    }
  }, [isReviewSuccess, dispatch]);
  // Owl Carousel options
  const options = {
    items: 3,
    loop: true,
    margin: 20,
    autoplay: true,
    autoplayTimeout: 5000,
    dots: true,
    responsive: {
      0: {
        items: 1, // Show 1 item for small screens
      },
      600: {
        items: 2, // Show 1 item for small tablets
      },
      1000: {
        items: 3, // Show 2 items for tablets and larger
      },
    },
    // autoWidth: true, // Optional: Ensure the items are properly sized
  };

  return (
    <>
      {isLoading ? (
        <p>Loading...</p> // Placeholder for loader
      ) : (
        <>
          {reviews.length !== 0 && (
            <Container
              fluid
              className="d-flex flex-column align-items-center justify-content-center triumph-div text-center riders-reviews-div">
              <Row className="justify-content-center riders-reviews">
                <h3>{t('front_page.home_riders_reviews')}</h3>
                <div className="m-3 riders-reviews">
                  <div className="carousel-container review-sld">
                    <OwlCarousel options={options}>
                      {reviews.length > 0 ? (
                        reviews.map((rider, index) => (
                          <div key={index} className="carousel-slide d-flex justify-content-center">
                            <div className="review-col p-3">
                              {/* First Row: Quote Icon and Description */}
                              <Row className="mb-4 d-flex text-center">
                                {/* Left Side: Quote Icon */}
                                <Col xs="auto" className="quote-icon pt-1">
                                  <img
                                    src={quoteImg}
                                    alt={rider.riderName}
                                    className="rider-quote"
                                  />
                                </Col>
                                {/* Right Side: Review Text */}
                                <Col>
                                  <p className="review-text mb-0">
                                    {rider.reviewText[`${getCurrentLanguage()}`]}
                                  </p>
                                </Col>
                              </Row>
                              {/* Second Row: Rider Image and Name */}
                              <Row className="d-flex align-items-center justify-content-left text-center">
                                <Col xs="auto">
                                  <div className="rider-image-container">
                                    <img
                                      src={rider.riderImage}
                                      alt={
                                        rider.firstName[`${getCurrentLanguage()}`] +
                                        ' ' +
                                        rider.lastName[`${getCurrentLanguage()}`]
                                      }
                                      className="rider-image"
                                    />
                                  </div>
                                </Col>
                                <Col xs="auto">
                                  <span className="rider-name mb-0">
                                    {rider.firstName[`${getCurrentLanguage()}`] +
                                      ' ' +
                                      rider.lastName[`${getCurrentLanguage()}`]}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>{t('front_page.home_no_riders_reviews')}</p>
                      )}
                    </OwlCarousel>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
                  <Button className="review-btns">
                    <span>{t('front_page.home_review_us_btn_text')}</span>
                  </Button>
                  <Button className="review-btns">
                    <span>{t('front_page.home_review_view_more_btn_text')}</span>
                  </Button>
                </div>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
}
Review.propTypes = {
  t: PropTypes.func.isRequired, // Assuming t is a function
};
export default Review;
