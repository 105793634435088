import React, { useEffect } from 'react';
import 'assets/css/spinner.css';
export default function LoadingSpinner() {
  useEffect(() => {
    // document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
  return (
    <div className="spinner-container no-padding">
      <div className="loading-spinner"></div>
    </div>
  );
}
