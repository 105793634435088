import { createSlice } from '@reduxjs/toolkit';
const intialState = {
  front_logo: '',
  favicon_icon: '',
};
export const settingSlice = createSlice({
  name: 'setting',
  initialState: intialState,
  reducers: {
    addSetting: (state, { payload }) => {
      state.front_logo = payload.front_logo;
      state.favicon_icon = payload.favicon_icon;
      return state;
    },
    clearSetting: () => intialState,
  },
});
export const { addSetting, clearSetting } = settingSlice.actions;
export const settingData = (state) => state.setting;
export default settingSlice.reducer;
